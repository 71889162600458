@font-face {
  font-family: Gotham-medium;
  src: url(fonts/Gotham-Medium.otf);
}

@font-face {
  font-family: Gotham-light;
  src: url(fonts/Gotham-Light.otf);
}

@font-face {
  font-family: Gotham-black;
  src: url(fonts/Gotham-Black.otf);
}

@font-face {
  font-family: Gotham-bold;
  src: url(fonts/Gotham-Bold.otf);
}

@font-face {
  font-family: Gotham-book;
  src: url(fonts/Gotham-Book.otf);
}

@font-face {
  font-family: Gotham-thin;
  src: url(fonts/Gotham-Thin.otf);
}

@font-face {
  font-family: Gotham-ultra;
  src: url(fonts/Gotham-Ultra.otf);
}

@font-face {
  font-family: Gotham-xlight;
  src: url(fonts/Gotham-XLight.otf);
}

body {
  margin: 0;
  font-family: Gotham-medium, 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Gotham-medium, 'sans-serif';
}

* {
  font-family: Gotham-medium, 'sans-serif';
}
