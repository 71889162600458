.lista-terapias {
  display: flex;
  flex-direction: column;
  width: 60%;

  @import "detalleTerapia/dialogoCrearTerapia/dialogo-crear-terapia";

  .btn-crear-terapia {
    margin-top: 2em;
    padding: 0.5em 1.5em;
    width: fit-content;
    background-color: $terapeuta;
    border: 0;
    color: white;
  }

  .sk-btn-crear-terapia {
    width: 15%;
    height: 2em;
    margin-top: 2em;
  }

  .btn-crear-terapia:focus {
    box-shadow: 0 0 0 0.2rem $terapeura-sombra;
  }

  .sin-terapias {
    margin-top: 2em;
    background: #FFFFFF 0 0 no-repeat padding-box;
    @include box-shadow(3px, 3px, 6px, 0, #00000029);
    @include border-radius(0.9em);
    width: 100%;
    padding: 0.5em 0;

    .texto {
      text-align: center;
      font-size: 1.2em;
      font-weight: 600;
    }
  }

  .terapias-grilla {
    margin-top: 2em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2em;
    grid-column-gap: 2em;

    .contenedor-terapia {
      cursor: pointer;
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 90%;
      padding: 1.2em;
      height: fit-content;
      position: relative;

      .eliminar-terapia {
        position: absolute;
        top: -15px;
        right: -15px;
      }

      .espacio-texto {
        margin: 1rem 1.5em;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nombre-terapia {
          font-weight: 700;
          margin: 0;
          width: 60%;
        }

        .tareas-pacientes {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 40%;

          .texto {
            margin: 0;
            font-weight: 500;
          }
        }
      }
    }

    .sk-contenedor-terapia {
      width: 100%;
      height: 8em;
    }
  }
}

@media screen and (max-width: 800px) {
  .lista-terapias {
    width: 80%;
    display: flex;
    flex-direction: column;

    .sk-btn-crear-terapia {
      width: 35%;
    }

    .sin-terapias {
      width: auto;
      align-self: stretch;
      padding: 0.5em 1em;
      font-size: 0.8em;
    }

    .terapias-grilla {
      width: auto;
      align-self: stretch;
      grid-template-columns: 1fr;

      .contenedor-terapia {
        width: auto;

        .espacio-texto {
          margin: 0.5em 0;
        }
      }

      .sk-contenedor-terapia {
        height: 6em;
      }
    }
  }
}