/*
Estilos de la clase terapeuta
*/
.pagina-terapeuta {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: $gris-5;
    font-size: 16px;
    overflow-x: hidden;

    @import "../general/loadingIndicator/loading-indicator.sass";
    @import "components/resumen/resumen";
    @import "./components/dialogoAsignarTerapia/dialogo-asignar-terapia";
}

.pantalla-terapeutas {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: $gris-5;
}

.fila-flex {
    display: flex;
}

.contenido-terapeuta {
    display: flex;
    width: 100%;
    justify-content: center;
    @import "./../general/citas/citas";
    @import "./../general/citas/itemCita/item-cita";
    @import "./../general/Perfil/perfil";
    @import "./components/pacientes/pacientes.sass";
    @import "components/pacientes/perfilPacienteTerapeuta/perfil-paciente-terapeuta";
    @import "./components/terapias/terapias";
    @import "components/terapias/detalleTerapia/detalle-terapia";
    @import "components/pacientes/perfilPacienteTerapeuta/revisionTerapia/revision-terapia";
    @import "components/pacientes/perfilPacienteTerapeuta/revisionTerapia/revisarTarea/revisar-tarea";
}

.info-terapeuta {
    width: 70%;
    min-height: 80vh;
}

/*
Estilos del menú del terapeuta
*/
.foto-perfil {
    border-radius: 50%;
    width: 35%;
    cursor: pointer;
}

.imagen-activa {
    border: 5px solid #cafffb;
}

.nombre-terapeuta {
    margin: 0.3rem 0 1.5rem;
    font-size: 1.5vw;
}

.opcion-menu {
    display: flex;
    background-color: white;
    padding: 0 0 0 1rem;
    border-top: 1px solid #b2b2b2;
    width: 70%;
    cursor: pointer;
}

.opcion-menu-desplegable {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0 0 0 1rem;
    border-top: 1px solid #b2b2b2;
    width: 70%;
    cursor: pointer;
}

.lista-desplegable {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-left: 1rem;
    transition: 1s all ease;
    overflow: hidden;
}

.desaparecer {
    display: none;
}

.opcion-activa {
    background-color: #cafffb;
}

.ic-desplegable {
    width: 10%;
    background-color: white;
    padding-right: 1rem;
    padding-left: 1rem;
}

.ic-desplegable-margin {
    width: 10%;
    background-color: white;
    padding: 0 1rem;
    margin-left: auto;
}

.texto-menu {
    margin: 0;
    padding: 1.2rem 0 1.2rem 1rem;
    font-size: 1.5vw;
    font-weight: 300;
    background-color: white;
}

.texto-activo {
    font-weight: bold;
    color: #636363;
    width: 100%;
}

/*
Estilos del perfil del terapeuta
*/
.contenedor-perfil {
    display: flex;
    flex-direction: column;
    margin: 2rem 3.5rem;
}

.titulo-perfil {
    font-size: 3.5vw;
}

.fila-dato {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #b2b2b2;
}

.campo {
    width: 40%;
    font-size: 1.2vw;
}

.valor {
    width: 60%;
    font-size: 1.2vw;
}

.editable {
    border: unset;
}

.editable.descripcion {
    margin-top: 0.8rem;
}

.editable:focus {
    outline: none;
    border-bottom: 2px solid #65fff4;
}

.btn-editar {
    width: fit-content;
    margin-left: 1rem;
    margin-top: 2rem;
    padding: 0.5rem 1.5rem;
    background: #cafffb 0 0 no-repeat padding-box;
    border-radius: 19px;
    cursor: pointer;
    border: 0;
}

.btn-editar:active {
    box-shadow: 0 0 0 0.2rem #65fff4;
}

.btn-editar:focus {
    box-shadow: 0 0 0 0.2rem #65fff4;
    outline: unset;
}

.titulos-terapeuta {
    width: fit-content;
    padding: 0.2rem 1rem;
    font-size: 2vw;
    display: flex;
    align-items: center;
    color: black;
}

.titulos-terapeuta button {
    font-size: 1.5vw;
    width: 2vw;
    height: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    border-radius: 100%;
    background: #cafffb;
    margin-left: 1vw;
    font-weight: lighter;
    cursor: pointer;
    outline: none;
    color: black;
}

.terapia-preview {
    width: 40%;
}

.terapia-preview .contenedor-titulo-terapia {
    background-color: rgb(212, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-top-right-radius: 1vw;
    border-top-left-radius: 1vw;
    padding: 0.5vw 1vw;
    border-top: solid 1px rgb(154, 197, 197);
    border-left: solid 1px rgb(154, 197, 197);
    border-right: solid 1px rgb(154, 197, 197);
}

.terapia-preview .contenedor-titulo-terapia span {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotateZ(270deg);
    width: 2vw;
    height: 2vw;
    cursor: pointer;
}

.terapia-preview .contenedor-titulo-terapia .opciones-terapia {
    position: absolute;
    background-color: rgb(229, 229, 229);
    width: 45%;
    border-radius: 1vw;
    padding: 0.5vw 1vw;
    bottom: -5.5vw;
    right: 0.5vw;
    display: none;
}

.terapia-preview .contenedor-titulo-terapia .opciones-terapia button {
    background: none;
    border: none;
    width: 100%;
    text-align: start;
    font-size: 1vw;
    padding: 0.5vw 0;
}

.terapia-preview .contenedor-titulo-terapia .opciones-terapia div {
    background-color: white;
    width: 100%;
    height: 0.15vw;
}

.contenedor-info-terapia {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom-right-radius: 1vw;
    border-bottom-left-radius: 1vw;
    padding: 0.5vw 1vw;
    border-bottom: solid 1px rgb(154, 197, 197);
    border-left: solid 1px rgb(154, 197, 197);
    border-right: solid 1px rgb(154, 197, 197);
    font-size: 1.2vw;
}

.formulario-dialogo {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 25px 0;

    input {
        background: none;
        border: none;
        border-bottom: solid 1px rgb(201, 201, 201);
        padding-bottom: 3px;
        margin-bottom: 20px;
        color: rgb(38, 38, 38);
        font-weight: bold;

        &::placeholder {
            color: rgb(38, 38, 38);
            font-weight: bold;
        }
    }

    div {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;

        button {
            margin: 0 2%;
        }
    }
}

.btn-actividades {
    width: 10.5em;
    font-size: 11px;
}

.btn-actividad {
    width: fit-content;
    font-size: 10px;
    align-self: flex-end;
    margin-right: 3em;
    margin-bottom: 1em;
}

.btn-adjuntar {
    width: 9em;
    font-size: 10px;
    align-self: flex-end;
    margin-right: 3em;
    margin-bottom: 1em;
}

.espacio-vacio {
    font-size: 11px;
    width: 10.5em;
    margin-right: 3em;
}

.contenedor-recursos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: 0 5%;

    .video {
        width: 40%;
    }

    .contenedor-recurso{
        width: 40%;
        margin: 1em;
        border-radius: 0.7em;
        overflow: hidden;

        @media (max-width: 800px) {
            width: 90%;
            margin: 1em auto;
        }
    }

    // iframe {
    //     width: 350px;
    //     height: 200px;
    //     margin-right: 2em;
    //     margin-bottom: 1em;
        
    // }

    img {
        width: 150px;
        height: 100px;
        margin-right: 2em;
        margin-bottom: 1em;
        object-fit: contain;
    }

    a {
        width: 100%;
        color: $terapeuta;
        margin-bottom: 0.5em;
        max-width: 85%;
    }
}

.circulo-x {
    background: none;
    border-radius: 100%;
    width: 15px;
    height: 15px;
    border: solid 1px $terapeuta;
    font-size: 0.8em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-weight: bold;
    color: $terapeuta;
    outline: none;
    cursor: pointer;
}

.sk-titulo-tarea {
    width: 18em;
    height: 2.3em;
}

.sk-boton-crear {
    width: 8em;
    height: 2.3em;
    border-radius: 1em;
    overflow: hidden;
    margin-left: 1em;
}

.sk-descripcion-tarea {
    width: 100%;
    height: 6em;
}

.sk-actividad-tarea {
    width: 100%;
    height: 10em;
    border-radius: 1em;
    overflow: hidden;
    margin-bottom: 1em;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.4);
}
