.resumen-terapeuta {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;

  .titulo-bienvenida {
    font-size: 2em;
  }

  .sk-titulo-bienvenida {
    width: 40%;
    height: 3em;
    margin: 1.5em;
  }

  .contenedor-resumen {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .contenedor-blanco {
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 45%;
      padding: 1rem;
      height: fit-content;

      .btn-terapeuta-sin-datos {
        width: fit-content;
        background-color: $gris-btn;
        border: 0;
        color: white;
        padding: 0 1.5em;
      }

      .btn-terapeuta-sin-datos:focus{
        box-shadow: 0 0 0 0.2rem $gris-6;
      }

      .subtitulo {
        margin: 1em 0 1.5em;
      }

      .sk-subtitulo {
        height: 1.5em;
        width: 35%;
        margin-bottom: 1em;
      }

      .espacio-texto{
        margin: 1rem 2rem;

        .fila-info {
          display: flex;
          align-items: center;
          margin-bottom: 1em;

          .foto-persona {
            @include border-radius(50%);
            width: 2.5em;
            height: 2.5em;
          }

          .sk-foto-persona {
            height: 2.5em;
            width: 2.5em;
          }

          .nombre-paciente {
            padding-left: 1em;
            margin: 0;
            font-size: 1em;
            font-weight: 600;
          }

          .sk-nombre-paciente {
            height: 2em;
            width: 40%;
            margin-left: 1em;
          }

          .btn-naranja {
            background-color: #FF7D51;
            color: white;
            border: 0;
            cursor: pointer;
            min-width: fit-content;
            margin: 0 0.5em 0 auto;
          }

          .sk-btn-naranja {
            height: 2em;
            width: 27%;
            margin-left: auto;
          }

          .btn-naranja:focus{
            box-shadow: 0 0 0 0.2rem $terapeura-sombra;
          }
        }
      }
    }
  }
}

@media screen and ( max-width: 800px ){
  .resumen-terapeuta{
    .titulo-bienvenida {
      text-align: center;
    }

    .sk-titulo-bienvenida {
      width: 60%;
      height: 3em;
      margin: 2em;
    }

    .contenedor-resumen {
      flex-direction: column;
      align-items: center;

      .contenedor-blanco {
        width: 100%;
        margin-bottom: 2em;
        text-align: center;

        .sk-subtitulo {
          width: 70%;
          margin: 0 auto 1em;
        }

        .espacio-texto{
          margin: 0;

          .fila-info {
            .nombre-paciente {
              margin: auto;
              padding: 0 0.5em;
            }
          }
        }
      }
    }
  }
}