.dialogo-asignar-paciente {
  background-color: white;
  width: 28.125em;
  border-radius: 0.9375em;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16);
  font-size: 16px;
  max-width: 90%;
  z-index: 10;

  .contenido-dialogo-asignar-paciente{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    
  .datos-dialogo-asignar {
    width: 75%;
    margin-top: 1em;
    margin-bottom: 6.25em;

    .dialogo-field {
      padding: 0.1em 0;
      font-size: 1em;
    }

    .elegir-terapeuta{
      margin-bottom: 0.75em;
    }
  }
    > .error {
      width: 80%;
      text-align: center;
      margin-bottom: 1em;
      font-size: 0.8em;
    }

    .btns-dialogo-asignar {
      display: flex;
      justify-content: center;
      width: 40%;
      margin-bottom: 1em;
      font-size: 1.25em;
    
      .btn-azul {
        margin-left: 0.9em;
      }
    
    }
  }

  .no-hay-psicologos{
    margin: 3em 0;
  }

  .btns-dialogo-asignar-no-hay {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2.5em;
  }
  
  #btn-de-acuerd-asignar{
    font-size: 0.8em;
  }
}

@media screen and (max-width: 500px) {
  .dialogo-asignar-paciente {
    font-size: 3.5vw;
  }
}

@media screen and (min-width: 1600px) {
  .dialogo-asignar-paciente {
    font-size: 1vw;
  }
}