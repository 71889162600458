.custom-select {
  user-select: none;
  background-color: white;
  position: relative;
  border-radius: 5px;
  height: 1.875em;
  border: 0.125em solid grey;
  outline: none;
  display: flex;
  align-items: center;
  z-index: 5;
  width: calc(100% - 0.125em);
  cursor: pointer;

  .seleccion-actual{
    padding-left: 0.3125em;
  }

  .flecha-decoracion{
    position: absolute;
    right: 0.3125em;
    color: white;
    font-weight: bold;
    width: 1.5em;
    border-radius: 0.625em 0.625em;
    display: grid;
    place-items: center;
    padding: 0.05em;
    font-size: 1em;
  }

  .terapeuta{
    background-color: $terapeuta;
  }

  .administrador{
    background-color: $administrador;
  }
  
}

.lista-opciones {
  position: absolute;
  margin-top: -1.25em;
  padding-top: 1.25em;;
  border: 0.0625em solid grey;
  overflow-y: scroll;
  
  width: calc(100%);

  padding-bottom: 0.625em;
  background-color: white;

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }
    
    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
    
    /* Radius */
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
    }
    
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    
    .ul-opciones {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .ul-opciones > li {
    margin: 0;
    list-style-type: none;
    padding: 1% 0 1% 3.5%;
    user-select: none;
    cursor: pointer;
  }
  
  
  .ul-opciones > li:hover { 
    color: white;
  }
  
  .ul-opciones > .terapeuta:hover { 
    background-color: $terapeuta;
  }
  
  .ul-opciones > .administrador:hover { 
    background-color: $administrador;
  }
}