.division-gris-nosotros
    padding: 10em 0 4em
    width: 100%

    @media screen and ( max-width: 800px )
        flex-direction: column
        align-items: center

        .letra-grande
            text-align: center

        .imagen-nosotros
            width: 30%
            margin: 0 0 1em

.imagen-nosotros
    width: 8%
    margin-right: 5em

    img
        width: 100%

@import ./components/textos/textos