body
    color: $gris-2
    background-color: $gris-5

.flex
    display: flex

.center
    display: flex
    justify-content: center
    align-items: center

.jc-space-around, .justify-around
    justify-content: space-around

.jc-space-between, .justify-between
    justify-content: space-between

.jc-end, .justify-end
    justify-content: flex-end

.jc-start, .justify-start
    justify-content: flex-start

.jc-center, .justify-center
    align-items: center

.ai-center, .justify-center
    align-items: center

.ai-start, .items-start
    align-items: flex-start

.ai-end, .items-end
    align-items: flex-end

.as-end
    align-self: flex-end

.as-start
    align-self: flex-start

.as-center
    align-self: center

.flex-column, .flex-col
    flex-direction: column

.flex-reverse, .flex-row-reverse
    flex-direction: row-reverse

.flex-column-reverse, .flex-col-reverse
    flex-direction: column-reverse

.flex-wrap, .wrap
    flex-wrap: wrap

.text-align-start
    text-align: start

.text-align-end
    text-align: end

.text-align-center
    text-align: center

.text-align-justify
    text-align: justify


.contenedor-landing
    background-color: white
    background-size: 100%
    width: 100%
    min-height: 100vh
    display: flex
    flex-direction: column
    margin: 0 auto
    margin-top: 0
    position: relative

.boton
    background: none
    display: flex
    justify-content: center
    align-items: center
    color: $gris-1
    text-decoration: none
    border: none
    font-weight: 500
    padding: 0
    font-size: 1.1em
    width: 8em
    height: 1.5em
    cursor: pointer
    outline: none
    

.fondo-blanco-difuminado
    background-color: rgba(235, 235, 235)
    background: linear-gradient(180deg, rgba(245, 245, 245, 0) 90%, rgba(245, 245, 245, 0.5) 95%, rgb(255, 255, 255))


.letra-grande
    font-size: 4.5em
    font-weight: bold

    @media screen and ( max-width: 1050px )
        font-size: 4em

.letra-mediana
    font-size: 1.8vw

.letra-mediana-2
    font-size: 1.9vw

.fondo-blanco
    background-color: white

.fondo-gris-claro
    background-color: $fondo-gris

.fondo-gris
    background-color: $gris-2

.borde-blanco
    border: solid 0.15vw white

.letra-blanca
    color: white

.letra-seleccionada
    color: $gris-2

.letra-no-seleccionada
    color: $gris-3
    
.letra-azul
    color: $administrador

.letra-verde
    color: $paciente

.letra-gris
    color: $gris-1

.letra-gris-claro
    color: $gris-8

.bold-900
    font-weight: 900

.bold-600
    font-weight: 600

.bold-500
    font-weight: 500
    
.bold-400
    font-weight: 400

.bold-300
    font-weight: 300

.bold-200
    font-weight: 200

.bold-100
    font-weight: 100

.bold
    font-weight: bold

.bold-light
    font-weight: lighter

.letra-pequena
    font-size: 0.8em
    letter-spacing: 0
    line-height: 1.6em

.letra-titulos
    font-size: 1.8em

.letra-seccion-titulo
    font-size: 2.1em

.letra-seccion-nosotros
    font-size: 2.5em

.letra-secciones
    font-size: 0.9em
    letter-spacing: 0
    line-height: 1.6em

.letra-menus
    font-size: 1em
    letter-spacing: 0
    line-height: 1.6em

.letra-nombres
    font-size: 1.5em

.letra-aliados
    font-size: 1.1em

.base-boton
    background: none
    border: none
    cursor: pointer
    outline: none
    text-decoration: none

.btn-arketipos
    +border-top-left-radius(10px 17px)
    +border-bottom-left-radius(10px 17px)
    +border-top-right-radius(10px 17px)
    +border-bottom-right-radius(10px 17px)
    font-weight: 500
    font-size: 0.75em
    user-select: none
    width: 9em
    height: 2.8em
    color: black
    background-color: white
    border: 1px solid black
    cursor: pointer

.btn-registro
    // background-color: $administrador
    border: none
    color: white
    padding: 0
    font-size: 1.1em
    width: 10em
    height: 1.5em

.btn-arketipos:focus
    outline: 0
    @include box-shadow(0, 0, 0, 0.2rem, rgba(150, 150, 150, 0.57))

.btn-administrador
    background-color: $administrador
    color: white
    border: 0
    cursor: pointer

.btn-administrador:focus
    @include box-shadow(0, 0, 0, 0.2rem, $administrador-sombra)

.btn-paciente
    background-color: $paciente
    color: white
    border: 0
    cursor: pointer

.btn-paciente:focus
    @include box-shadow(0, 0, 0, 0.2rem, $paciente-sombra)

.btn-terapeuta
    background-color: $terapeuta
    color: white
    border: 0
    cursor: pointer

.btn-terapeuta:focus
    @include box-shadow(0, 0, 0, 0.2rem, $terapeura-sombra)

.btn-pequeno
    width: 7.5em

.fondo-borde-azul
    border: 1px solid $administrador
    background-color: $administrador
    color: white

.fondo-borde-azul:focus
    @include box-shadow(0, 0, 0, 0.2rem, $administrador-sombra)


.fondo-borde-amarillo
    border: 1px solid $amarillo
    background-color: $amarillo
    color: white

.fondo-borde-amarillo:focus
    @include box-shadow(0, 0, 0, 0.2rem, $amarillo-sombra)


.fondo-borde-verde
    border: 1px solid $paciente
    background-color: $paciente
    color: white

.fondo-borde-verde:focus
    @include box-shadow(0, 0, 0, 0.2rem, $paciente-sombra)

.fondo-borde-naranja
    border: 1px solid $terapeuta
    background-color: $terapeuta
    color: white

.fondo-borde-naranja:focus
    @include box-shadow(0, 0, 0, 0.2rem, $terapeura-sombra)

.text-decoration-naranja
    hr
        height: 3px
        margin: 0 auto
        width: 90%
        border-radius: 10px
        border: none
    &:hover
        hr
            background-color: #FF7D51

.text-decoration-amarillo
    hr
        height: 3px
        margin: 0 auto
        width: 90%
        border-radius: 10px
        border: none
    &:hover
        hr
            background-color: $amarillo

.text-decoration-verde
    hr
        height: 3px
        margin: 0 auto
        width: 90%
        border-radius: 10px
        border: none
    &:hover
        hr
            background-color: #2EC573

.text-decoration-azul
    hr
        height: 3px
        margin: 0 auto
        width: 90%
        border-radius: 10px
        border: none
    &:hover
        hr
            background-color: #418EEE

.botones-dialogo-dos
    display: flex
    width: 80%
    justify-content: space-between

.transicion
    transition: 0.5s all ease

.fondo-blanco
    background-color: white

.fondo-azul
    background-color: $administrador

.fondo-verde
    background-color: $paciente

.fondo-amarillo
    background-color: $amarillo

.text-blanco
    color: white
    
.text-azul
    color: $administrador

.text-verde
    color: $paciente

.text-amarillo
    color: $amarillo

.w-full
    width: 100%
.column
    flex-direction: column

.btn-agenda
    width: 15rem
    border: none
    height: 3rem
    font-size: 1.2rem
    font-weight: bold
    margin-top: 0.5rem

@media (min-width: 800px)
    .btn-agenda
        width: 18rem
        border: none
        height: 3.5rem
        font-size: 1.5rem
        font-weight: bold

.separador-landing
    height: 0.15em
    width: 90%
    border: none
    border-radius: 0.1em
    margin: 0.3em 0 1em
    transition: 1s all ease
    background-color: $administrador

.separador-landing-gris
    background-color: $gris-3
    width: 100%

.cls-1
    fill: $administrador
    transition: 0.5s all ease

.cls-2
    fill: white

.cls-azul
    fill: $administrador

.cls-amarillo
    fill: $amarillo

.cls-verde
    fill: $paciente

.input-buscar
    margin: 0 0 1rem
    input
        border-radius: 5px
        padding: 0.5rem 1rem
        border: solid 1px $gris-8

.input-index
    display: inline-block
    width: 2rem
    margin: 0.5rem

iframe
    display: none !important
div iframe
    display: block !important