.dialogo-eliminar {
    background-color: white;
    max-height: 95vh;
    width: 450px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16);

    >.error {
      text-align: center;
      width: 70%;
      margin-bottom: 1em;
    }
  }

  @media (max-width: 500px) { 
    .dialogo-eliminar {
      width: 90%;
    }
  }

  .btns-dialogo-eliminar {
    display: flex;
    justify-content: center;
    width: 40%;
    margin-bottom: 20px;
  
    .btn-azul {
      margin-left: 10px;
    }
  
    @media (max-width: 700px) { 
      .btn-borde-negro {
        font-size: 12px;
        padding: 5px 15px;
        min-width: 90px;
  
      }
      .btn-azul {
        font-size: 12px;
        padding: 5px 15px;
        min-width: 90px;
      }
    }
  
    @media (max-width: 500px) { 
      .btn-azul {
        font-size: 12px;
        padding: 5px 15px;
        min-width: 90px;
      }
    }
  }

  .eliminar-nombre-img{
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: fit-content;
      margin: 1.25em;
  }

  .profile-eliminar-img{
      object-fit: cover;
      width: 46px;
      height: 46px;
      margin-right: 1vw;
      border-radius: 50%;
  }