footer
    display: flex
    justify-content: space-around
    align-items: flex-start
    width: 80%
    padding: 2.5em 0 1.5em
    margin: 0 10%
    background-color: white

    .imagen-header
        width: 15%
        
        svg
            width: 100%

    hr
        height: 0.15em
        width: 100%
        border: none
        border-radius: 0.1em
        margin: 0.3em 0 1em
        transition: 1s all ease

    .hr-verde
        background-color: $paciente

    .hr-azul
        background-color: $administrador

    .hr-amarillo
        background-color: $amarillo

    .opciones-footer
        color: $gris-3
        
        a
            margin: 0.5em 0

    .circulo
        background-color: white
        margin: 0
        text-decoration: none
        color: $gris-3
        margin-bottom: 1.5em
        height: 2em

        img
            width: 2em
            height: 2em
            object-fit: contain
            margin-right: 0.4em

        &:visited
            color: $gris-3

@media screen and ( max-width: 800px )
    footer
        flex-direction: column
        position: relative
        font-size: 18px
        border: none

        .imagen-header
            width: 50%

        .opciones-header
            display: flex
            flex-direction: column
            align-items: center
            width: 100%
            margin: 0.5em 0

            .opcion-header
                width: 100%
                font-size: 1.5em

    .circulos-footer
        width: 100vw

        .circulo
            width: 15vw
            height: 15vw

            img
                width: 60%
                height: 60%
                object-fit: contain
