.perfil-admin{
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 5fr;
    grid-template-areas: "foto perfil";
    justify-items: center;
    width: 65%;
    height: fit-content;
    margin: 20px 0;
    font-size: 16px;

    .seccion-foto-cambiar{
        grid-area: foto;
        align-self: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 180px;

        .img-perfil-admin{
            border-radius: 50%;
            object-fit: cover;
            width: 140px;
            height: 140px;
            margin-bottom: 15px;
        }

        @media (max-width: 500px) { 
            .btn-azul{
                font-size: 13px;
            }
        }
    }

    .sk-seccion-foto-cambiar {
        grid-area: foto;
        margin: 20px 0;
        height: 11.5486em;
        width: 11.25em;
        min-width: 11.25em;
    }

    .contenedor-perfil{
        width: 75%;
        grid-area: perfil;
        margin: 20px 0;
        font-size: 16px;
        .titulo-perfil-admin{
            font-size: 2em;
            font-weight: bold;
            width: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        
            hr {
                width: 85%;
                margin: 0;
                border: none;
                height: 4px;
                border-radius: 5px;
            }
        
            @media (max-width: 500px) { 
                hr{
                    width: 150px;
                }
            }
        }
    
        .fila-dato{
            font-size: 16px;
            
            .editable {
                margin: 10px 0px;
                border: unset;
            }
            
            .editable.descripcion {
                margin-top: 0.8rem;
            }
            
            .editable-administrador:focus {
                outline: none;
                border-bottom: 2px solid $administrador;
            }

            .editable-terapeuta:focus {
                outline: none;
                border-bottom: 2px solid $terapeuta;
            }

            .editable-paciente:focus {
                outline: none;
                border-bottom: 2px solid $paciente;
            }

            .campo{
                font-weight: bold;
            }

            .campo, .valor{
                font-size: 18px;
                word-wrap: break-word;
            }

            .sk-valor {
                height: 1.6669em;
                margin: 1.125em 0em;
                width: 60%;
            }

            .sk-descripcion {
                width: 60%;
                height: 4.6875em;
                margin: 1.125em 0em;
            }

            @media (max-width: 400px) { 
                .valor, .campo{
                    font-size: 15px;
                }
            }
    
            @media (max-width: 300px) { 
                .valor, .campo{
                    font-size: 12px;
                }
            }
        }

        > button {
            margin-top: 2em;
            width: fit-content;
        }

        .btn-azul{
            margin-top: 20px;
            width: fit-content
        }

        .sk-btn-azul {
            margin-top: 20px;
            width: 6.3944em;
            height: 1.8613em;
        }

        @media (max-width: 500px) { 
            .btn-azul{
                font-size: 13px;
            }
        }

        > .error {
            margin-top: 1em;
            text-align: center;
            font-size: 1em;
        }
    }

    @media (max-width: 500px) { 
        .contenedor-perfil{
            width: 90%;
        }
    }

    .background-administrador {
        background-color: $administrador;
    }

    .background-terapeuta {
        background-color: $terapeuta;
    }

    .background-paciente {
        background-color: $paciente;
    }

    .btn-administrador {
        background-color: $administrador;
        color: white;
        border: none;
    }

    .btn-terapeuta {
        background-color: $terapeuta;
        color: white;
        border: none;
    }

    .btn-paciente {
        background-color: $paciente;
        color: white;
        border: none;
    }
}

@media (max-width: 1200px) { 
    .perfil-admin{
        width: 90%;
    }
}

@media (max-width: 650px) { 
    .perfil-admin{
        grid-template-columns: 1fr;
        grid-template-areas: "perfil"
                             "foto";
    }
}