.contenedor-revision-terapia {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;

  @media screen and (max-width: 800px) {
    width: 90%;
  }

  .fila-atras {
    display: flex;
    align-items: center;
    margin-top: 1em;
    cursor: pointer;
    width: fit-content;
    align-self: flex-start;

    
    .texto-atras {
      font-size: 1em;
      margin: 0 0 0 0.5em;
    }

    .sk-texto-atras {
      width: 4em;
      height: 1em;
      margin: 0 0 0 0.5em;
    }

    .btn-atras {
      font-size: 16px;
      width: 2.25em;
      margin: 0 0.37em 0 0;
      padding: 0.3em;
      height: fit-content;
    }

    .sk-btn-atras {
      width: 2.25em;
      height: 2em;
      margin: 0 0.37em 0 0;
    }
  }

  .titulo-terapia {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1em 0 0.5em;

    .nombre-terapia {
      font-size: 1.5em;
      font-weight: 600;
      text-align: center;
      margin: 0;
    }

    .sk-titulo-terapias {
      height: 2em;
      width: 40%;
      margin: 0;
    }
  }

  .contenedor-tareas {
    margin-top: 2em;
    width: 100%;

    .sin-tareas {
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 100%;
      padding: 0.5em 0;

      .texto {
        text-align: center;
        font-size: 1.2em;
        font-weight: 600;
      }
    }

    .sk-tarea {
      width: 100%;
      height: 3.5em;
      margin-bottom: 1em;
    }

    .con-tareas {
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 0 1em 0;
      padding: 0.2em 0;

      .circulo-revisar {
        background-color: $terapeuta;
        height: 1.2em;
        width: 1.2em;
        position: absolute;
        margin-left: 1em;
        border-radius: 50%;
        border: 1px solid white;
      }

      .texto {
        font-size: 1.2em;
        font-weight: 600;
        width: 80%;
        cursor: pointer;
        margin: 0;
        padding: 1em 0 1em 3em;
      }

      .estado {
        display: flex;
        width: 20%;
        justify-content: center;

        .tarea-revisada {
          color: $terapeuta;
          font-style: italic;
          margin: 0 1.5em 0 auto;
          padding: 0.2em 1em;
        }

        .switch-activo {
          margin-left: auto;
          margin-right: 2em;
          z-index: 100;
        }
      }
    }
  }

  .btn-finalizar {
    align-self: flex-end;
    margin-bottom: 2em;
  }
}