.dialogo-agregar-terapeuta
    background-color: white
    width: 28.125em
    border-radius: 0.9375em
    display: flex
    flex-direction: column
    align-items: center
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16)
    font-size: 16px
    max-width: 90%

    > .contenido-dialogo
        width: 80%
        display: flex
        flex-direction: column
        align-items: center

        .titulo-dialogo
            margin-top: 1em
            font-size: 1.1em
            font-weight: bold
            text-align: center
            hr
                height: 0.3125em
                background-color: $administrador
                border: none
                border-radius: 5px
                width: 8em
                margin: 0 auto 1em auto

        input
            width: 100%
            outline: none
            font-size: 0.8em
            border: 0
            background-color: white
            border-bottom: 2px solid rgba(223, 223, 223, 1)
            margin-bottom: 1em
      

        input:focus
            border-bottom: 2px solid $administrador

        .error
            text-align: center
            width: 70%
            margin-bottom: 1em
            font-size: 0.8em
            
        .botones
            display: flex
            justify-content: space-between
            width: 70%
            margin-bottom: 1em

            .btn-agregar
                background-color: $administrador
                color: white
                border: none

@media screen and ( max-width: 500px)
    .dialogo-agregar-terapeuta
        font-size: 3vw
    
@media screen and ( min-width: 1600px)
    .dialogo-agregar-terapeuta
        font-size: 1vw
      
