.contenedor-pacientes
    width: 60%
    display: flex
    flex-direction: column
    align-items: stretch
    font-size: 16px
    margin: 1.25em 0
    min-width: 28.125em

    .item-paciente
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0.7813em 0

        .paciente-nombre-img
            width: fit-content
            max-width: 12.5em
            display: flex
            align-items: center

            .profile-img
                border-radius: 50%
                width: 2.5em
                height: 2.5em
                object-fit: cover
                margin-right: 0.625em

            .sk-profile-img
                width: 2.5em
                height: 2.5em
                margin-right: 0.625em

            .nombre-usuario
                font-weight: 600

            .sk-nombre-usuario
                width: 9.375em
                height: 1.5em

            .circulo-revisar
                background-color: $terapeuta
                height: 0.8em
                width: 0.8em
                border-radius: 50%
                position: absolute
                transform: translateX(1.75em) translateY(-1em)
                border: 1px solid white

        .sk-btn-arketipos
            width: 6.875em
            height: 2.125em

.texto-sin-pacientes
    padding: 5em 1em
    font-size: 1.75em
    font-weight: bold
    text-align: center


@media (max-width: 500px)
    .contenedor-pacientes
        font-size: 3vw

@media (min-width: 1600px)
    .contenedor-pacientes
        font-size: 1vw
