.contenedor-citas {
    margin: 2rem 0 0 0;
    width: 75%;
    min-width: 28.75em;
    justify-self: center;
    font-size: 16px;
    z-index: 10;

    .contenedor-sin-citas{
        display: grid;
        place-items: center;
        background-color: white;
        @include box-shadow(6px, 3px, 6px, 0px, #00000029);
        height: 8.077em;
        border-radius: 0.75em;
        font-weight: bold;
        font-size: 1.3em;
    }

    .titulo-citas{
        font-weight: bold;
        font-size: 1.44em;
        display: flex;
        align-items: center;
        margin-bottom: 1.2em;
    }

    .titulo-paciente{
        justify-content: center;
        font-size: 2em;
    }

    .btn-agregar-cita{
        color: white;
        border: none;
        display: flex;
        background-color: $terapeuta;
        align-items: center;
        justify-content: space-around;
        margin-left: 0.625em;
        font-size: 0.5em;
        cursor: pointer;
    }

    .sk-btn-agregar-cita{
        margin-left: 0.625em;
        height: 1.5em;
        width: 4.2em;
    }

    @import './dialogoCita/dialogo-cita';
}

@media screen and (max-width: 700px) {
    .contenedor-citas {
        font-size: 2.5vw;
        width: 90%;
    }
}

@media screen and (min-width: 1600px) {
    .contenedor-citas {
        font-size: 1vw;
    }
}
