.contenedor-cita-terapeuta{
    grid-template-columns: 4em 1fr 6em;
    grid-template-areas:
      "img-fecha fecha editar"
      "img-link link ."
      "img-nombre nombre  eliminar";
}

.contenedor-cita-paciente{
    grid-template-columns: 4em 1fr 2fr;
    grid-template-areas:
      "img-fecha fecha link"
      "img-nombre nombre .";
}

@media screen and (max-width: 1150px){
    .contenedor-cita-paciente{
        grid-template-columns: 4em 1fr;
        grid-template-areas:
          "img-fecha fecha"
          "link link"
          "img-nombre nombre";
        }
}

.contenedor-cita{
    display: grid;
    grid-row-gap: 1em;
    justify-items: start;
    align-items: center;
    border-radius: 0.75em;
    // width: 100%;
    // min-width: 26.25em;
    padding: 1.25em 2.5em;
    margin-bottom: 2em;
    background-color: white;
    @include box-shadow(6px, 3px, 6px, 0px, #00000029);

    .fecha-cita{
        grid-area: fecha;
        font-size: 1em;
        min-width: 16.25em;
    }
    
    .sk-fecha-cita{
        width: 15em;
        max-width: 20.125em;
        height: 1.5em;
    }

    img {
        width: 2em;
    }

    .img-fecha{
        grid-area: img-fecha;
        justify-self: start;
    }

    .sk-img-fecha{
        width: 2em;
        height: 2em;
        justify-self: start;
    }

    .editar-cita{
        grid-area: editar;
        justify-self: right;
        align-self: flex-start;
        font-size: 1em;
        cursor: pointer;
    }

    .eliminar{
        display: flex;
        grid-area: eliminar;
        justify-self: right;
        align-items: center;
        font-size: 1em;
        cursor: pointer;

        .img-eliminar{
            width: 1.4em;
            margin-right: 0.5em;
        }
    }

    .sk-editar-cita{
        width: 2.875em;
        height: 1.5em;
        justify-self: end;
    }

    .img-link{
        grid-area: img-link;
    }
    
    .img-link-terapeuta {
        justify-self: start;
    }

    .img-link-paciente {
        justify-self: right;
    }

    .sk-img-link{
        height: 2em;
    }
    
    .sk-img-link-terapeuta{
        height: 1.5em;
        width: 20em;        
        justify-self: start;
    }

    .sk-img-link-paciente{
        height: 1.5em;
        width: 80%;        
        justify-self: right;
        margin-right: 1em;
    }

    .link-cita-paciente{
        justify-self: right;
        > span {
            margin-left: 2em;
            width: 25em;
            word-wrap: break-word;
        }
    }

    @media screen and (max-width: 1150px){
        .link-cita-paciente{
            justify-self: left;
            > span {
                width: 25em;
                word-wrap: break-word;
            }
        }

        .sk-img-link-paciente{
            grid-area: link;
            justify-self: left;
            height: 1.5em;
            width: 70%;        
        }
    }

    .link-cita-terapeuta{
        justify-self: left;
        > span {
            max-width: 50vw;
            word-wrap: break-word;
        }
    }

    .link-cita{
        font-size: 1em;
        display: flex;
        align-items: center;
        grid-area: link;
        

        .img-copiar{
            margin-left: 0.5em;
            justify-self: center;
            width: 1.25em;
            cursor: pointer;
        }
    }
 
    .sk-link-cita{
        height: 2em;
    }
    
    .sk-link-cita-terapeuta{
        width: 2em;
    }

    .sk-link-cita-paciente{
        width: 100%;
        justify-self: right;
    }

    .sk-eliminar-cita{
        grid-area: eliminar;
        width: 5.875em;
        height: 1.5em;
    }

    .img-perfil-cita{
        grid-area: img-nombre;
        justify-self: start;
    }

    .sk-img-perfil-cita{
        grid-area: img-nombre;
        width: 2em;
        height: 2em;
        justify-self: start;
    }
    
    .nombre-paciente-cita{
        grid-area: nombre;
        font-size: 1em;
        min-width: 16.25em;
    }

    .sk-nombre-paciente-cita{
        grid-area: nombre;
        width: 16.25em;
        height: 1.5em;
    }
}
