@import ../general/styles/colores

.contenedor-registro
    min-height: 100vh
    width: 100%
    display: flex
    flex-direction: column
    background: white
    justify-content: flex-start
    align-items: center
    position: relative

    form
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin: 10vw 0 8vw

        h1
            padding: 0
            margin: 1vw 0
            font-size: 3.6vw
            color: black

        p
            text-align: center
            padding: 0
            margin: 0 0 1.5vw
            font-size: 1.2vw
            font-weight: 600

        input
            width: 38vw
            height: 3vw
            padding: 0 2vw
            display: flex
            justify-content: flex-start
            align-items: center
            border-radius: 1vw
            border: solid 0.1vw black
            margin: 0.5vw 0
            font-size: 1.3vw
            color: $gris-1
            font-weight: 600
            outline: none

            &::placeholder
                color: $gris-1
                font-weight: 600

        textarea
            width: 38vw
            min-height: 5vw
            max-height: 13vw
            padding: 1vw 2vw
            justify-content: flex-start
            align-items: center
            border-radius: 1vw
            border: solid 0.1vw black
            margin: 0.5vw 0
            font-size: 1.3vw
            color: $gris-1
            font-weight: 600
            outline: none
            resize: none

            &::placeholder
                color: $gris-1
                font-weight: 600

        .boton-login
            padding: 0.7vw 1.7vw
            font-size: 1.4vw
            font-weight: 600
            border-radius: 1.5vw
            margin: 1vw 0
            outline: none
            align-self: flex-start