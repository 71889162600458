.contenedor-aliados
    width: 100%

    @media screen and ( max-width: 800px )
        flex-direction: column
        align-items: center

.contenedor-aliado
    width: 17em
    margin: 0 1em

    div
        height: 5em
        width: 100%

.boton-aliado
    color: $paciente
    border: none
    text-decoration: none
    font-size: 0.9em
    padding: 0 1em
    font-weight: 900
    height: 2em
    margin-top: 0.5em