.contenedor-opciones-admin-landing
    margin-top: 4.5em
    padding: 2em 0

    .opciones-admin-landing
        text-decoration: none
        margin: 0 2em
        color: $gris-1
        border-bottom: 0.3em solid transparent
        transition: 0.3s all ease

        &:hover
            border-color: $administrador

    .seleccionada
        border-color: $administrador

.opciones-opciones
    margin-top: 0
    
    .opciones-admin-landing
        width: fit-content

        &:hover
            border-color: $paciente
            
    .seleccionada
        border-color: $paciente

.contenedor-formualrios-landing
    width: 80%
    padding: 0 10%

    form
        width: 100%

    .campo-landing
        margin-top: 1em
        width: fit-content
        
        input
            width: 35vw
            padding: 0.5em 1em

        textarea
            width: 70vw
            height: 12em
            resize: none
            padding: 1em

        input[type="file"]
            display: none
        
        .btn-arketipos
            color: white
            width: 14em
            margin-right: 2em
            border: none

        a
            width: fit-content

    .opcion-arketipo
        width: fit-content


    button
        margin-top: 2em
        border: none
        color: white
        width: 14em

    .boton-eliminar
        background-color: $rojo
        margin-left: 3em
        