.dialogo-aprobar-paciente
    background-color: white
    width: 28.125em
    border-radius: 0.9375em
    display: flex
    flex-direction: column
    align-items: center
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16)
    font-size: 16px
    max-width: 90%

    .contenido-dialogo
        width: 80%
        display: flex
        flex-direction: column
        align-items: stretch

        .titulo-dialogo
            margin-top: 1em
            font-size: 1.1em
            font-weight: bold
            text-align: center
            hr
                height: 0.3125em
                background-color: $administrador
                border: none
                border-radius: 5px
                width: 8em
                margin: 0 auto 1em auto

        .contenedor-dato
            display: flex
            font-size: 0.9em
            margin-bottom: 0.5em

            .nombre-dato
                font-weight: bold
                margin-right: 0.5em

            .valor
                font-size: 1em
                word-wrap: break-word

            .informacion
                text-align: justify

        .botones
            margin: 1em 0 
            display: flex
            justify-content: space-between
            align-self: center
            width: 60%
            font-size: 0.9em

            .btn-aprobar
                background-color: $administrador
                color: white
                border: none

        .error
            font-size: 0.8em
            align-self: center
            text-align: center
            width: 70%

@media screen and (max-width: 500px)
    .dialogo-aprobar-paciente
        font-size: 3vw

@media screen and (min-width: 1600px)
    .dialogo-aprobar-paciente
        font-size: 1vw