.resumen-panel {
  width: 75%;
  margin-bottom: 1.875em;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;

  .texto-bienvenido {
    text-align: center;
    font-size: 3em;
    font-weight: bold;
    padding: 0.125em 0;
    margin: 0.25em 0;
  }

  .sk-texto-bienvenido {
    height: 5.1667em;
    width: 24.6075em;
    max-width: 60%;
    padding: 0.625em 0;
    margin: 0.25em 0;
  }

  > .contenedor-paneles {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(31.25em, 1fr));
    column-gap: 2.5em;
    row-gap: 2.5em;
    width: 100%;
    min-width: 65.625em;

    .resumen-con-pacientes-admin {
      width: 100%;
      background-color: white;
      box-shadow: 3px 3px 6px #00000029;
      border-radius: 0.5625em;
      display: flex;
      justify-content: center;
      padding: 1.5625em 0px;

      .pacientes-sin-asignar-admin {
        width: 75%;

        .titulo-pacientes-sin-asignar {
          font-weight: bold;
          font-size: 1.1875em;
        }

        .sk-titulo-pacientes-sin-asignar {
          height: 1.7225em;
          width: 20.3125em;
          max-width: 90%;
          min-width: 2.6313em;
        }

        .item-paciente-por-asignar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.4375em 0;

          .img-nombre-paciente {
            display: flex;
            align-items: center;
            width: 40%;
            .paciente-profile-img {
              border-radius: 50%;
              width: 2.5em;
              height: 2.5em;
              object-fit: cover;
              margin-right: 0.625em;
            }

            .sk-paciente-profile-img {
              min-width: 2.5em;
              height: 2.5em;
            }

            .nombre-item {
              font-size: 0.9375em;
              font-weight: bold;
            }

            .sk-nombre-item {
              margin-left: 0.625em;
              width: 100%;
              height: 1.5em;
            }
          }

          .btns-paciente-por-asignar {
            display: flex;
            min-width: 50%;
            justify-content: flex-end;

            .sk-btn-borde-negro {
              max-width: 5.4236em;
              width: 100%;
              height: 1.7361em;
            }

            #btn-asignar-resumen {
              margin-left: 1.2em;
            }
          }
        }

        .sk-item-paciente-por-asignar {
          padding: 0.4375em 0;
          height: 2.5em;
          width: 100%;
        }

        #btn-sin-pacientes {
          margin-top: 1.25em;
          padding: 0.4688em 0.9375em;
          border-radius: 0.75em;
          cursor: auto;
        }
      }
    }
  }

  @media screen and (max-width: 1100px){
    .contenedor-paneles {
      min-width: auto;
    }
  }
}

@media screen and (max-width: 700px) {
  .resumen-panel{
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .resumen-panel{
    font-size: 2.8vw;
  }
}
