@import ../general/styles/colores

.contenedor-dialogo-login
    width: 100vw
    height: 100vh
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    background-color: rgba(255, 255, 255, 0.4)

    .parte-atras-dialogo
        width: 100vw
        height: 100vh
        position: absolute
        top: 0
        left: 0


.boton-login
    padding: 0
    font-size: 0.8em
    font-weight: 600
    margin: 0 10%
    align-self: flex-start
    background-color: $administrador
    border: none
    color: white
    width: 6em
    height: 2.4em
    outline: none
    text-decoration: none
    display: flex
    justify-content: center
    align-items: center

.btn-recuperar
    width: 10em
    align-self: center
    margin-bottom: 1em

.contenedor-login
    width: 30em
    max-width: 90%
    max-height: 80vh
    overflow: auto
    display: flex
    flex-direction: column
    background: white
    justify-content: flex-start
    align-items: center
    position: relative
    +border-radius(1em)
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4)
    position: relative
    border: 1vh white solid

    form
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        margin: 0
        width: 100%

        p
            width: 70%
            margin-bottom: 1em

        div
            padding: 0
            margin: 2em 0 1em
            font-size: 1em
            color: black
            font-family: Gotham
            text-align: center
            font: normal normal bold Gotham
            letter-spacing: 0px
            color: #161616
            opacity: 1

            hr
                margin-top: 0.2em
                margin-left: 7%
                border: none
                width: 86%
                height: 0.2em
                background-color: $administrador
                +border-radius(2em)

        input
            width: 80%
            padding: 0
            padding-bottom: 0.4em
            display: flex
            justify-content: flex-start
            align-items: center
            border: none
            border-bottom: solid 0.1vw black
            margin: 0.5vw 0
            font-size: 1em
            color: $gris-1
            font-weight: 500
            outline: none
            background: none
            margin-bottom: 1.3em
            
            &:focus
                border-bottom: solid 0.2vw $administrador

            &::placeholder
                color: $gris-1
                font-weight: 500

        .input-recuperar
            text-align: center

        textarea
            width: 75%
            min-height: 4em
            max-height: 5em
            padding: 0.7em
            justify-content: flex-start
            align-items: center
            border-radius: 1vw
            border: solid 0.1vw black
            margin: 0.5vw 0
            font-size: 1em
            color: $gris-1
            font-weight: 600
            outline: none
            resize: none

            &::placeholder
                color: $gris-1
                font-weight: 600

            &:focus
                border: solid 0.2vw $administrador
        
        .boton-recuperar-contrasena
            background: none
            font-size: 1em
            border: none
            outline: none
            cursor: pointer
            margin: 2em 0 1em

            &:hover
                text-decoration: underline
                text-decoration-color: $administrador
    
    .pregunta-registro
        display: flex
        border-top: solid 2px $gris-3
        padding: 1em 0
        margin-bottom: 2em
        width: 70%
        justify-content: space-around
        align-items: center

        span
            margin: 0 0.5em
            color: $gris-1
            font-size: 1em

        .boton-login
            margin: 0

.botones-registro
    display: flex
    width: 80%
    justify-content: center

    button
        margin: 0 1em

    .btn-registro-usuario
        width: 10em
        border: $administrador solid 1px

    .btn-registro-blanco
        width: 10em
        background-color: white
        border: black solid 1px
        color: black

@media screen and ( max-width: 800px )
    .pregunta-registro
        flex-direction: column
        justify-content: center
        align-items: center

    .boton-login
        align-self: center
        width: 60%