.contenedor-detalle-terapia {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;

    .fila-atras {
      display: flex;
      align-items: center;
      margin-top: 1em;
      cursor: pointer;
      width: fit-content;
      align-self: flex-start;

      .texto-atras {
        font-size: 1em;
        margin: 0 0 0 0.5em;
      }

      .sk-texto-atras {
        width: 4em;
        height: 1em;
        margin: 0 0 0 0.5em;
      }

      .btn-atras {
        font-size: 16px;
        width: 2.25em;
        margin: 0 0.37em 0 0;
        padding: 0.3em;
        height: fit-content;
      }

      .sk-btn-atras {
        width: 2.25em;
        height: 2em;
        margin: 0 0.37em 0 0;
      }
    }

  .titulo-tarea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1em 0 0.5em;

    .titulo-terapias {
      font-size: 1.5em;
      font-weight: 600;
      text-align: center;
      margin: 0;
    }

    .sk-titulo-terapias {
      height: 2em;
      width: 40%;
      margin: 0;
    }

    .btn-crear-tarea {
      margin-left: 3em;
    }

    .sk-btn-crear-tarea {
      margin-left: 3em;
      width: 10%;
      height: 2em;
    }
  }

  .descripcion-terapia {
    width: 60%;
    font-size: 0.9em;
    text-align: center;
    font-weight: 500;
    line-height: 1.2em;
    margin: 0.5em 0;
  }

  .sk-fila-texto {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5em;

    .sk-descripcion-terapia-1 {
      width: 70%;
      height: 1em;
      margin: 0.5em 0;
    }

    .sk-descripcion-terapia-2 {
      width: 55%;
      height: 1em;
      margin: 0;
    }
  }

  .contenedor-tareas {
    margin-top: 2em;
    width: 100%;

    .sin-tareas{
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 100%;
      padding: 0.5em 0;

      .texto {
        text-align: center;
        font-size: 1.2em;
        font-weight: 600;
      }
    }

    .sk-tarea {
      width: 100%;
      height: 3.5em;
      margin-bottom: 1em;
    }

    .con-tareas{
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 100%;
      padding: 0.2em 0;
      display: flex;
      align-items: center;
      margin-bottom: 1em;

      .texto {
        padding-left: 2em;
        font-size: 1.2em;
        font-weight: 600;
      }

      .editar {
        cursor: pointer;
        margin-left: auto;
        margin-right: 1em;
        padding: 0.2em 1em;
      }

      .escribir-descripcion{
        width: 92%;
        display: flex;
        align-items: flex-start;
        margin-top: 1em;
        padding: 1em 4%;

        span{
          font-size: 0.9em;
          margin-right: 1em;
        }

        textarea{
          resize: none;
          min-height: 4em;
          padding: 0.6em;
          width: 100%;
          outline: none;
          caret-color: $terapeuta;
          &:focus{
            border: solid 2px $terapeuta;
            padding: calc(0.6em - 1px);
          }
        }
      }

      .botones-crear-actividad{
        display: flex;
        width: 94%;
        margin-left: 6%;
        justify-content: space-between;
      }
    }

    .contenedor-actividades{
      flex-direction: column;
      align-items: flex-start;

      .texto{
        font-size: 0.8em;
      }

      .contenedor-adjuntar{
        width: 85%;
        display: flex;
        align-items: flex-start;

        .adjuntos{
          color: $terapeuta;
          font-size: 0.8em;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          
          div{
            margin-right: 1em;
            display: flex;
            margin-top: 0.5em;
            max-width: 25%;
            white-space: nowrap;
            text-overflow: ellipsis;

            span{
              margin-right: 0.5em;
              white-space: nowrap;
              max-width: 80%;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .contenedor-detalle-terapia {
    width: 90%;

    .titulo-tarea {
      display: flex;
      flex-direction: column;
      margin-top: 0;

      .titulo-terapias {
        order: 2;
      }

      .sk-titulo-terapias {
        width: 40%;
        order: 2;
      }

      .btn-crear-tarea {
        order: 1;
        margin-left: 0;
        align-self: flex-end;
        margin-bottom: 2em;
      }

      .sk-btn-crear-tarea {
        order: 1;
        width: 30%;
        margin-bottom: 2em;
        align-self: flex-end;
      }
    }
  }
}