
.imagen-circular
    width: 100%
    height: 100%
    overflow: hidden
    border-radius: 100%
    overflow: hidden

    img
        width: 65%
        height: 65%
        object-fit: contain

    .completa
        width: 100%
        height: 100%
        object-fit: cover

.primera-division
    margin-top: 4em
    padding: 7em 0
    display: flex
    justify-content: space-around
    align-items: center

    &>div
        margin:  0
        margin-left: 5%

        div
            width: 6em
            font-family: Gotham-Black

        p
            font-size: 2em
            margin: 0.2em 0
            font-family: Gotham-Bold
            width: 19em

            @media screen and ( max-width: 1050px )
                width: 14em

    .imagen-inicio
        width: 20em
        height: 20em
        min-width: 20em
        border-radius: 100%
        overflow: hidden
        margin-right: 5%
        
        &>div
            transition: 1s all ease-in-out

        .imagen-arketipo
            width: 20em
            height: 20em
            min-width: 20em

            img
                width: 65%
                height: 65%
                object-fit: contain

        
        @media screen and ( max-width: 1050px )
            width: 15em
            height: 15em
            min-width: 15em
            margin: 0

            .imagen-arketipo
                width: 15em
                height: 15em
                min-width: 15em
            

.boton-leer-mas
    font-size: 1.5em
    color: $administrador
    padding: 0
    border: none
    height: 1.5em

.segunda-division
    padding: 5vw 0

    .contenedor-texto-seccion
        width: 37%
        padding: 0 2%

        .boton-home
            font-size: 1em

    .letra-grande
        font-weight: 700
        margin: 0.5vw 0 2vw

    .imagen-seccion
        width: 25em
        height: 28em
        object-fit: cover
        border-radius: 1em
        margin: 0 2%

    .textos
        width: 38%
        overflow: hidden
        margin-left: 2%

        @media screen and ( max-width: 800px )
            width: 90%
            margin: 1em 5%

    .textos-izquieda
        width: 50%
        margin-left: 0

        @media screen and ( max-width: 800px )
            width: 90%
            margin: 1em 5%

    .contenedor-textos
        transition: 1s all ease-in-out
        padding-right: 8vw

        .contenedor-texto-publicacion
            width: 30vw
            height: 26em
            padding: 0 0 0 4vw
            transition: 1s all ease-in-out
            flex: 0 0 auto

        .contenedor-texto-publicacion-izq
            height: 16em

        .vacio
            height: 8em

            @media screen and ( max-width: 800px )
                display: none
    
        @media screen and ( max-width: 800px )
            padding-right: 0

            .contenedor-texto-publicacion
                width: 80vw
                height: auto
                max-height: 20em
                padding: 0 5vw

                .letra-titulos
                    font-size: 1.4em

            .contenedor-texto-publicacion-izq
                height: auto
                max-height: 20em

    @media screen and ( max-width: 800px )
        flex-direction: column

        .contenedor-texto-seccion
            width: 90%
            padding: 0 5% 2em

        .imagen-seccion
            width: 15em
            height: 18em
            

    .imagenes-carrusel
        overflow: hidden
        transition: 1s all ease-in-out
        // background-color: gray

        .imagen-publicacion
            width: 30em
            height: 26em
            object-fit: contain
            border-radius: 1em
            margin-left: 3em
            transition: 1s all ease-in-out

        .imagen-servicio
            width: 25em
            height: 18em
            object-fit: contain
            border-radius: 1em
            margin-left: 3em
            transition: 1s all ease-in-out

        @media screen and ( max-width: 800px )
            padding-right: 13vw
            padding-left: 13vw

            .imagen-publicacion, .imagen-servicio
                width: 68vw
                height: 12em
                margin: 0 3vw
            

    .btn-arketipos
        padding: 0.5em 1.3em
        text-decoration: none

.contenedor-imagen-equipo
    width: 11em
    height: 11em
    margin: 1em 0 2em

.contenedor-imagen-servicios
    width: 9em
    height: 9em

.integrante
    width: 18em

    .letra-nombres
        margin-bottom: 0.5em

.contenedor-equipo
    padding: 4em 0 8em

    .contenedor-integrantes
        width: 90%

    .letra-titulos
        margin: 1em 0 1.5em
        font-size: 2em
        
    @media screen and ( max-width: 800px )

        .contenedor-integrantes
            flex-direction: column
            width: 100%
            align-items: center
        

@media screen and ( max-width:  800px)
    .primera-division
        font-size: 10px
        flex-direction: column-reverse

        &>div
            margin:  0

            div
                text-align: center
                width: 90%
                margin: auto

            p
                font-size: 1.5em
                text-align: center
                width: 80%
                margin: auto

        img
            width: 40%
            margin-bottom: 2em

    .boton-leer-mas
        margin: 1em auto 0
        font-size: 2em


.sk-letra-grande
    height: 6em
    width: 25em !important

.sk-texto-sentido
    height: 4em
    width: 30em !important

.sk-letra-titulos
    height: 4em
    width: 55%

.sk-parrafo
    height: 13em
    width: 100%

.sk-imagen-carrusel
    width: 30em
    height: 26em
    border-radius: 1em
    overflow: hidden

.sk-imagencircular
    width: 11em
    height: 11em