.pagina-administrador {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: $gris-5;
  font-size: 16px;
  overflow-x: hidden;
  position: relative;

  .contenedor-administrador {
    height: max-content;
    width: 100vw;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    @import "./../general/Perfil/perfil";
    @import "./components/pacientesPsicologo/pacientes-terapeuta.sass";
    @import "./components/terapiasPaciente/terapiasPaciente.scss";
    @import "./components/terapiasPaciente/tareasPaciente/tareasTerapia.scss";
    @import "./../administrador/components/PerfilUsuario/perfil-usuario-admin.scss";
    @import "./../administrador/components/ResumenAdministrador/resumen-administrador";
    @import "./../administrador/components/Gestionar/gestionar";
  }

  @import "./../administrador/components/DialogoAsignar/dialogo-asignar";
  @import "./../administrador/components/dialogoAprobar/dialogo-aprobar";
  @import "./../administrador/components/dialogoAgregarTerapeuta/dialogo-agregar-terapeuta";
  @import "./../administrador/components/DialogoEliminar/dialogo-eliminar";

  @import "../general/loadingIndicator/loading-indicator.sass";

  .btn-borde-negro {
    background-color: white;
    padding: 0.3125em 0.9375em;
    border: 0.125em solid black;
    font-weight: bold;
    font-size: 0.75em;
    min-width: 7em;
    user-select: none;
    width: auto;
  }

  .btn-azul {
    border: 1px solid $administrador;
    color: white;
    background-color: $administrador;
    padding: 0.3125em 0.9375em;
    font-weight: bold;
    font-size: 0.75em;
    min-width: 7em;
    user-select: none;
    width: auto;
  }
}

.pagina-administrador button {
  cursor: pointer;
  outline: none;
}

.fondo-dialogo {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  background-color: rgba(220, 220, 220, 0.58);
  display: grid;
  place-items: center;
  overflow-y: auto;
  z-index: 10;
}

.dialogo-titulo {
  margin-top: 1.25em;
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;

  .linea-dialogo {
    background-color: $administrador;
    border: none;
    margin: 0 auto;
    height: 0.25em;
    width: 85%;
    border-radius: 0.125em;
  }

  .fondo-naranja {
    background-color: $terapeuta;
  }
  .fondo-verde {
    background-color: $paciente;
  }
}

.dialogo-titulo hr {
  height: 0.01px;
  background-color: black;
}

.path-usuario-admin{
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: space-between;
  font-size: 20px;
  cursor: pointer;
  user-select: none;

  .flecha-decoracion{
      right: 5px;
      background-color: $administrador;
      color: white;
      font-weight: bold;
      height: 25px;
      width: 30px;
      border-radius: 40%;
      display: grid;
      place-items: center;
      padding: 1px;
      font-size: 16px;
      vertical-align: middle;
      margin-right: 10px;
      
      span{
          margin-top: -2px;
      }
  }
  
}