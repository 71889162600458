.contenedor-textos-nosotros
    margin: 4em 0
    width: 100%

    &>div
        width: 80%
        margin-bottom: 2em        

        button
            width: 9em
            transition: 0.5s all ease

            &:hover
                color: $amarillo

            hr
                width: 60%
                background-color: transparent
                height: 0.2em
                border: none
                margin: 0.2em 0
                border-radius: 1em
                transition: 0.5s all ease


            .hr-seleccionado
                background-color: $amarillo

        .boton-seleccionado
            color: $amarillo

    @media screen and ( max-width: 800px )
    
        &>div
            width: 95%
            margin-bottom: 2em        

            button
                width: 9em
                transition: 0.5s all ease
                font-size: 1em

                &:hover
                    color: $amarillo

                hr
                    width: 60%
                    background-color: transparent
                    height: 0.2em
                    border: none
                    margin: 0.2em 0
                    border-radius: 1em
                    transition: 0.5s all ease


                .hr-seleccionado
                    background-color: $amarillo

            .boton-seleccionado
                color: $amarillo

    &>p
        width: 76%
        line-height: 2.4