.contenedor-chat
    width: 65em
    max-width: 90%
    margin: 1.5em auto
    display: flex
    justify-content: center
    align-items: flex-start
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.5)
    +border-radius(1em)
    background-color: white
    overflow: hidden

    .contenedor-chats
        width: 25%
        display: flex
        flex-direction: column
        align-items: center

        .contenedor-buscar
            margin-top: 1em
            max-width: 60%

            input
                border-radius: 1em
                background: $gris-6
                padding: 0.4em 0.8em
                outline: none
                border: none
                font-size: 0.8em
                border: solid 1px transparent
                color: $gris-1
                max-width: 100%

                &::placeholder
                    color: $gris-1

            .input-terapeuta
                &:focus
                    border: solid 1px $terapeuta
                    
                    

        .lista-chats
            width: 100%
            margin-top: 1em

            .usuario-chat
                width: 80%
                display: flex
                justify-content: flex-start
                align-items: center
                padding: 0.5em 10%
                cursor: pointer

                .contenedor-imagen
                    width: 2em
                    height: 2em
                    min-width: 2em
                    min-height: 2em
                    
                    img
                        width: 100%
                        height: 100%
                        object-fit: cover
                        border-radius: 100%
                        overflow: hidden

                span
                    font-size: 0.8em
                    margin-left: 1em

                &:hover
                    background-color: $gris-7



.chat
    width: 75%
    margin: auto
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    border-left: 1px solid $gris-6

    .informacion-chat
        width: 90%
        display: flex
        padding: 1em 5%
        align-items: center
        border-bottom: 1px solid $gris-6

        img
            width: 2.2em
            height: 2.2em
            object-fit: cover
            border-radius: 100%
            overflow: hidden

        .cerrar-chat
            border: none
            cursor: pointer
            width: 1.5em
            height: 1.5em

        span
            font-size: 1em
            margin-left: 1em

    .contenedor-mensajes
        width: 90%
        background: white
        height: 45vh
        overflow-x: hidden
        overflow-y: auto
        padding: 0.3em 5%
        display: flex
        flex-direction: column

        .contenedor-fecha
            width: 100%
            display: flex
            flex-direction: column
            align-items: center
            
            .fecha-mensaje
                margin: 1em 0
                font-size: 0.9em

        .contenedor-mensaje
            max-width: 80%
            padding: 0.6em 3%
            margin: 0.7em 0 0
            +border-radius(1.5em)
            display: flex

            .mensaje
                text-align: start
                font-size: 0.85em
                position: relative
            
            .hora-mensaje
                font-size: 0.7em
                float: right
                margin-top: 1.5em
                margin-left: 0.75em

        .mensaje-paciente
            align-self: flex-start
            background-color: $gris-4


        .mensaje-psicologo
            align-self: flex-end
            background-color: $azul
            color: white
        
    .contenedor-enviar-mensaje
        padding: 0.5em 2% 1.3em
        width: 96%
        display: flex
        justify-content: space-between

        textarea
            width: 85%
            +border-radius(2em)
            outline: none
            padding: 0.5em 1.5em
            max-height: 8em
            resize: none
            font-size: 0.8em
            display: flex

            &::placeholder
                color: $gris-1

        
        .textarea-terapeuta
            &:focus
                border: solid 1px $terapeuta

            &::selection 
                color: white
                background: $terapeuta
        
        button
            width: 6em
            height: 2.5em
            border: none
            color: white
            margin-left: 3%
            outline: none
                    
.fondo-terapeuta
    background-color: $terapeuta !important

.fondo-paciente
    background-color: $paciente !important

.cerrar-char
    width: 1.5em
    height: 1.5em
    min-width: 1.5em
    display: flex
    justify-content: center
    align-items: center
    background: none
    border: solid 1px black
    font-size: 1.4em
    +border-radius(100%)

@media screen and ( max-width: 800px )
    .chat-movil
        .contenedor-chats
            width: 100%
            overflow: hidden
            transition: 1s all ease
        
        .chat
            width: 0
            overflow: hidden
            transition: 1s all ease

        .lista-chat-seleccionado
            width: 0
            transition: 1s all ease

        .chat-seleccionado
            width: 100%
            transition: 1s all ease
