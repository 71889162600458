
.carrusel
    width: 60%
    overflow: hidden
    
    .vacio
        height: 8em

        @media screen and ( max-width: 800px )
            display: none

    @media screen and ( max-width: 800px )
        width: 100%

.flechas
    width: 100%
    height: 8em
    margin: 1em 0

    button
        width: 6.5em
        height: 5.5em
        margin-left: 2em

        img
            width: 80%
            height: 80%
            object-fit: contain

        .derecha
            transform: rotateY(180deg)

    .pequenos
        width: 4em
        height: 4em
        margin-left: 0
        margin-right: 1.5em
        padding: 0

        img
            width: 60%
            height: 100%
            object-fit: contain

.flechas-pequenas
    margin-left: 12vw