.contenedor-opciones {
    font-size: 16px;
    margin-top: 0.625em;
    display: grid;
    width: 100%;
    justify-items: center;
    height: 3.75em;
    align-items: center;
  
    .opcion {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .linea-opcion {
        margin: 0px;
        border: none;
      }
    }
  
    .opcion:hover > div {
      font-weight: bold;
      animation: fadeInOpacity 0.5s forwards;
    }
  
    .opcion:hover > .linea-opcion {
      height: 0.25em;
      border-radius: 0.1875em;
      border: none;
      width: 90%;
    }
  
    .opcion-seleccionada > div {
      font-weight: bold;
      animation: fadeInOpacity 0.5s forwards;
    }
  
    .opcion-seleccionada > .linea-opcion {
      height: 0.25em;
      border-radius: 0.1875em;
      border: none;
      width: 90%;
    }
  }
  
  @media screen and (max-width: 500px) {
      .contenedor-opciones{
          font-size: 3.25vw;
      }
  }
  
  @media screen and (min-width: 1550px) {
      .contenedor-opciones{
          font-size: 1vw;
      }
  }

  // TERAPEUTA

  .menu-terapeuta{
    grid-template-areas: ". resumen terapias citas pacientes chat .";
    grid-template-columns: repeat(7, 1fr);
  
    .opcion-menu-terapeuta{
      text-align: center;
    }
  
    .opcion-resumen{
      grid-area: resumen;
    }
  
    .opcion-pacientes{
      grid-area: pacientes;
    }
  
    .opcion-terapias{
      grid-area: terapias;
    }
  
    .opcion-citas{
      grid-area: citas;
    }

    .opcion-chat{
      grid-area: chat;
    }
  
    .opcion:hover > .linea-opcion{
      background-color: $terapeuta;
    }
  
    .opcion-seleccionada > .linea-opcion{
      background-color: $terapeuta;
    } 
  }
  
  @media screen and (max-width: 700px) {
      .menu-terapeuta{
          grid-template-areas: "resumen terapias citas pacientes chat";
          grid-template-columns: repeat(5, 1fr);
      }
  }

  // Administrador

  .menu-administrador{
    grid-template-areas: ". resumen pacientes terapeutas .";
    grid-template-columns: repeat(5, 1fr);

    .opcion-resumen{
        grid-area: resumen;
      }
    
      .opcion-pacientes{
        grid-area: pacientes;
      }
    
      .opcion-terapeutas{
        grid-area: terapeutas;
      }

      .opcion:hover > .linea-opcion{
        background-color: $administrador;
      }

      .opcion-seleccionada > .linea-opcion{
        background-color: $administrador;
      }
}

@media screen and (max-width: 700px) {
    .menu-administrador{
        grid-template-areas: "resumen pacientes terapeutas";
        grid-template-columns: repeat(3, 1fr);
    }
}

// Paciente

  // Administrador

  .menu-paciente{
    grid-template-areas: ". resumen terapias citas chat .";
    grid-template-columns: repeat(6, 1fr);

    .opcion-resumen{
        grid-area: resumen;
      }
    
      .opcion-terapias{
        grid-area: terapias;
      }
    
      .opcion-citas{
        grid-area: citas;
      }

      .opcion-chat{
        grid-area: chat;
      }

      .opcion:hover > .linea-opcion{
        background-color: $paciente;
      }

      .opcion-seleccionada > .linea-opcion{
        background-color: $paciente;
      }
}

@media screen and (max-width: 700px) {
    .menu-paciente{
        grid-template-areas: "resumen terapias citas chat";
        grid-template-columns: repeat(4, 1fr);
    }
}

.linea-menu{
  margin: 0px;
  height: 0.5px;
  width: 75%;
  background-color: #949494;
  border: none;
}

@media (max-width: 700px) { 
  .linea-menu{
    width: 90%;
  }
}