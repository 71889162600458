.contenedor-terapias {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding-bottom: 2em;

  .fila-atras {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    cursor: pointer;
    width: fit-content;
    align-self: flex-start;

    .texto-atras {
      font-size: 1em;
      margin: 0 0 0 0.5em;
    }

    .sk-texto-atras {
      width: 4em;
      height: 1em;
      margin: 0 0 0 0.5em;
    }

    .btn-atras {
      font-size: 16px;
      width: 2.25em;
      margin: 0 0.37em 0 0;
      padding: 0.3em;
      height: fit-content;
    }

    .sk-btn-atras {
      width: 2.25em;
      height: 2em;
      margin: 0 0.37em 0 0;
    }
  }

  .titulo-perfil-usuario-admin {
    position: relative;
    font-size: 2em;
    font-weight: bold;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 1em;

    .columna {
      display: flex;
      flex-direction: column;
      max-width: 40%;
    }

    hr {
      width: 100%;
      margin: 0;
      border: none;
      height: 4px;
      border-radius: 5px;
      background-color: $administrador;
    }

    @media (max-width: 500px) {
      hr {
        width: 150px;
      }
    }

    .btn-perfil-terapias {
      position: absolute;
      width: fit-content;
      padding: 0 1.5em;
      font-size: 0.5em;
      right: 0;
      margin-right: 15vw;
      background-color: transparent;
      border: $administrador 1px solid;
      color: $administrador;

      @media (min-width: 800px) and  (max-width: 1200px) {
        margin-right: 10vw;
      }
      @media (max-width: 800px) {
        margin-top: 1em;
        position: initial;
        margin-left: 0;
        height: fit-content;
        padding: 0.5em 1.5em;
        margin-right: 0;
      }
    }

    .btn-perfil-terapias:hover {
      background-color: $administrador;
      color: white;
    }

    @media (max-width: 800px) {
      flex-direction: column;
      margin-bottom: 1em;
    }
  }

  .sk-titulo-perfil-usuario-admin {
    width: 75%;
    height: 3.3056em;
  }

  @media (max-width: 350px) {
    .titulo-perfil-usuario-admin {
      font-size: 22px;
    }
  }

  .sin-terapias {
    margin-top: 1em;
    background: #ffffff 0 0 no-repeat padding-box;
    @include box-shadow(3px, 3px, 6px, 0, #00000029);
    @include border-radius(0.9em);
    width: 100%;
    padding: 0.5em 0;

    .texto {
      text-align: center;
      font-size: 1.2em;
      font-weight: 600;
    }
  }

  .lista-terapias {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2em;
    grid-column-gap: 2em;
    justify-items: center;
    width: 95%;

    .contenedor-blanco {
      cursor: pointer;
      background: #ffffff 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 90%;
      padding: 1.2em;
      height: fit-content;

      .espacio-texto {
        margin: 1rem 1.5em;

        .fila-titulo-terapia {
          // display: flex;
          align-items: center;
          margin-bottom: 0.7em;

          .titulo-terapia {
            margin: 0;
            font-weight: 700;
            font-size: 1.2em;
          }

          .tarea {
            margin-left: 1em;
            display: flex;
            flex-direction: column;
            align-items: center;
            float: right;

            .estado-terapia {
              margin: 0;
              font-size: 1.1em;
              font-style: italic;
              text-align: center;
            }

            .subrayado {
              background-color: $administrador;
              border: 0;
              height: 0.25em;
              margin: 0;
              @include border-radius(10rem);
              width: 90%;
            }
          }
        }

        .descripcion-terapia {
          margin: 0;
          font-size: 0.9em;
          font-weight: 500;
          text-align: justify;
        }
      }
    }

    .sk-contenedor-blanco {
      width: 93%;
      height: 8em;
    }
  }
}

@media screen and (max-width: 800px) {
  .contenedor-terapias {
    width: 95%;

    .lista-terapias {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
