.contenedor-servicios
    margin: 0

    .primera-division
        padding: 2em 0
        margin-top: 2.4em

    .menu-servicios
        padding: 1em 0
        margin: 1.5em 0 0

        button
            background: none
            border: none
            outline: none
            cursor: pointer
            margin: 0 1em
            width: 10em

            span
                margin: 0 1.2em 0.2em

            hr
                height: 0.3em
                width: 100%
                border: none
                border-radius: 1em
                transition: 1s all ease
                margin: 0.5em 0

        @media screen and ( max-width: 800px )
            width: 100%
            overflow: hidden
            font-size: 0.9em
            
            button
                margin: 0 0.7em
                width: fit-content
                padding: 0

                span
                    margin: 0 0.7em 0.2em

                hr
                    height: 0.3em
                    width: 100%
                    border: none
                    border-radius: 1em
                    transition: 1s all ease
                    margin: 0.5em 0

    .borde-abajo
        height: 0.3em
        width: 76%
        border: none
        border-radius: 1em
        transition: 1s all ease
        margin: 0 12%
        background-color: $gris-7

@media (max-width: 799px)
    .contenedor-servicios
        .primera-division
            padding-top: 5rem
        .text-container
            font-size: 1.5rem
            text-align: center
            padding: 0 0.5rem
            box-sizing: border-box


@import ./components/consultoria/consultoria
@import ./components/aliados/aliados