.contenedor-pacientes-terapeuta
    min-width: 28.125em
    width: 75%
    margin-top: 1.25em

    .path-usuario-admin
        margin-bottom: 1.5em

        .sk-nombre-terapeuta
            margin-left: 0.5em
            height: 1.5em
            width: 6.25em

    .sk-contenedor-paciente
        height: 5.3125em
        width: 100%
        margin-bottom: 1em
        
    .contenedor-paciente
        display: grid
        align-items: center
        grid-template-columns: repeat(auto-fill, minmax(7.5em, 1fr))
        border-radius: 0.75em
        font-size: 1.25em
        cursor: pointer
        padding: 0 3em
        height: 4.25em
        margin-bottom: 1em

        div
            font-size: 0.75em
            

        @include box-shadow(0.375em, 0.1875em, 0.375em, 0.2rem, #00000029)

@media screen and (max-width: 500px)
    .contenedor-pacientes-terapeuta
        font-size: 3vw