.detalle-terapia-paciente {
  width: 75%;
  margin: 0 auto 4em;

  .fila-atras {
    display: flex;
    align-items: center;
    margin-top: 1em;
    cursor: pointer;
    width: fit-content;

    .texto-atras {
      font-size: 1em;
      margin: 0 0 0 0.5em;
    }

    .sk-texto-atras {
      width: 4em;
      height: 1em;
      margin: 0 0 0 0.5em;
    }

    .btn-verde {
      width: 3em;
      margin: 0 0.5em 0 0;
      padding: 0.4em;
      height: fit-content;
    }

    .sk-btn-atras {
      width: 2.25em;
      height: 2em;
      margin: 0 0.37em 0 0;
    }
  }

  .contenedor-terapia {
    display: flex;
    flex-direction: column;
    align-items: center;

    .titulo-terapias {
      font-size: 1.7em;
      font-weight: 600;
      margin: 0;
      max-width: 50%;
      text-align: center;
    }

    .sk-titulo-terapias {
      height: 2em;
      width: 40%;
      margin: 0;
    }

    .descripcion-terapia {
      width: 60%;
      font-size: 0.9em;
      text-align: center;
      font-weight: 500;
      line-height: 1.2em;
      margin: 0.5em 0;
    }

    .sk-fila-texto {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 0.5em;

      .sk-descripcion-terapia-1 {
        width: 60%;
        height: 1em;
        margin: 0.5em 0;
      }

      .sk-descripcion-terapia-2 {
        width: 45%;
        height: 1em;
        margin: 0;
      }
    }

    .contenedor-tareas {
      margin-top: 1em;
      width: 100%;

      .sin-tareas {
        background: #ffffff 0 0 no-repeat padding-box;
        @include box-shadow(3px, 3px, 6px, 0, #00000029);
        @include border-radius(0.9em);
        width: 100%;
        padding: 0.5em 0;

        .texto {
          text-align: center;
          font-size: 1.2em;
          font-weight: 600;
        }
      }

      .sk-tarea {
        width: 100%;
        height: 4.6em;
        margin-bottom: 1em;
      }

      .con-tareas {
        background: #ffffff 0 0 no-repeat padding-box;
        @include box-shadow(3px, 3px, 6px, 0, #00000029);
        @include border-radius(0.9em);
        width: 100%;
        padding: 0.2em 0;
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        .texto {
          padding-left: 2em;
          font-size: 1.2em;
          font-weight: 600;
        }

        .editar {
          cursor: pointer;
          margin-left: auto;
          margin-right: 1em;
          padding: 0.2em 1em;
        }

        .contenedor-respuesta {
          width: 100%;
          display: flex;
          flex-direction: column;

          .circulo-x {
            color: $paciente;
            border-color: $paciente;
          }
        }

        .escribir-descripcion {
          width: 92%;
          display: flex;
          align-items: flex-start;
          margin-top: 1em;
          padding: 1em 4%;

          span {
            font-size: 0.9em;
            margin-right: 1em;
          }

          textarea {
            resize: none;
            min-height: 4em;
            padding: 0.6em;
            width: 100%;
            outline: none;
            caret-color: $paciente;
            &:focus {
              border: solid 2px $paciente;
              padding: calc(0.6em - 1px);
            }
          }
        }

        .botones-crear-actividad {
          display: flex;
          width: 94%;
          margin-left: 6%;
          justify-content: space-between;
        }

        .con-editar {
          justify-content: flex-end;
        }
      }

      .contenedor-actividades {
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: 1em;

        .texto {
          font-size: 0.8em;
        }

        .contenedor-adjuntar {
          width: 85%;
          display: flex;
          align-items: flex-start;

          .adjuntos {
            color: $paciente;
            font-size: 0.8em;
            width: 100%;
            display: flex;
            flex-wrap: wrap;

            div {
              margin-right: 1em;
              display: flex;
              margin-top: 0.5em;
              max-width: 25%;
              white-space: nowrap;
              text-overflow: ellipsis;

              span {
                margin-right: 0.5em;
                white-space: nowrap;
                max-width: 80%;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }

      .contenedor-recursos {
        margin-left: 3%;
        display: flex;
        flex-wrap: wrap;

        .video {
          width: 50%;
        }

        .contenedor-recurso{
          width: 45%;
          margin: 1em;
        }

        @media screen and (max-width: 800px) {
          .video {
            margin-bottom: 2em;
          }

          .contenedor-recurso{
            width: 100%;
            margin: 1em auto;
          }
        }

        img {
          width: 150px;
          height: 100px;
          margin-right: 2em;
          margin-bottom: 1em;
          object-fit: contain;
        }

        a {
          width: 100%;
          color: $gris-1;
          margin-bottom: 0.5em;
          max-width: 85%;
          font-size: 12px;
        }
      }

      .contenedor-marcar {
        align-self: flex-end;
        display: flex;
        align-items: center;

        span {
          font-size: 12px;
          margin-bottom: 10px;
          margin-right: 20px;
          width: 140px;
        }

        button {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          padding: 2px 4px;
        }
      }

      .btn-adjuntar {
        padding: 6px 10px;
        height: auto;
        width: auto;

        input {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .detalle-terapia-paciente {
    width: 95%;

    .contenedor-terapia {
      width: 100%;

      .titulo-terapias {
        margin: 1em 0;
        max-width: 90%;
      }
    }
  }
}
