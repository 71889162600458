.dialogo-crear-cita {
  background-color: white;
  width: 35vw;
  min-width: 28.125em;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5em 0;
  font-size: 1em;

  @include box-shadow(6px, 6px, 6px, 0px, #00000029);

  .contenido-dialogo-crear-cita {
    width: 80%;
    font-size: 1em;
  
    .titulo-dialogo-crear-cita {
      margin-top: 1em;
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;
      hr {
        height: 0.3125em;
        background-color: $terapeuta;
        border: none;
        border-radius: 5px;
        width: 8em;
      }
    }
  
    .dialogo-crear-cita-field{
      margin-bottom: 1.25em;
    
      .titulo-dialogo-crear-cita-field{
        margin-bottom: 0.3125em;
        font-weight: bold;
      }
    }
  
    .botones-dialogo-crear-cita{
      display: flex;
      justify-content: center;
      margin-bottom: 1.25em;
  
      > button {
        font-size: 0.7688em;
        padding: 0.24em 0;
      }
      
      @media screen and (max-width: 500px) {
        > button {
          font-size: 1em;
          width: 30%;
        }
      }
      
      > .btn-cancelar-dialogo-crear-cita{
        border: 2px solid $gris-2;
        background-color: white;
        letter-spacing: 0px;
        color: $gris-2;
      }
      
      > .btn-crear-dialogo-crear-cita{
        background-color: $terapeuta;
        border: none;
        color: white;
        outline: none;
        cursor: pointer;
        margin-left: 0.95em;
      }
    }

    .error{
      text-align: center;
      color: $terapeuta;
      margin-bottom: 1em;
    }
  }
}

.dialogo-crear-cita-input{
    border-radius: 0.625em;
    border: 0.0625em solid #B2B2B2;
    width: calc(100% - 0.9vw - 2px);
    height: 2.3em;
    outline: none;
    padding: 0 0 0 0.8638em;
    font-size: 0.8em;
}

.hora-input-dialogo-crear-cita{
  border-radius: 0.625em;
    border: 0.0625em solid #B2B2B2;
    width: calc(100% - 0.9vw - 2px);
    height: 2.3em;
    outline: none;
    padding: 0 0 0 0.8638em;
    font-size: 0.8em;
}

.dialogo-crear-cita-horas{
  display: flex;
  justify-content: space-around;
  margin-bottom: 5vh;
}

.componente-hora-dialogo-crear-cita{
  display: flex;
  flex-direction: column;
  align-items: center;

  .titulo-dialogo-crear-cita-field{
    font-weight: bold;
  }
}