.gestionar-panel{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 20px 0;
    font-size: 16px;

    .btn-agregar-terapeuta{
        background-color: $administrador;
        border: none;
        color: white;
        width: fit-content;
        font-weight: normal;
        padding: 0 1vw;
    }

    
    @media (max-width: 500px) {   
        .btn-agregar-terapeuta{
            font-size: 3vw;
        }
    }    

}
.congelado {
    background-color: #EBEBEB;
}
.gestionar-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12.5px 0;

    .gestionar-nombre-img{
        width: 25%;
        display: flex;
        align-items: center;
        font-size: 16px;
    
        .nombre-usuario{
            font-weight: bold;
        }

        .sk-nombre-usuario{
            font-weight: bold;
            height: 1.5em;
            width: 100%;
        }

        @media (max-width: 500px) {   
            .nombre-usuario{
                font-size: 16px;
            }
        }
    }

    .gestionar-nombre-img-terapeuta{
        width: 40%;
        display: flex;
        align-items: center;
        font-size: 16px;
    
        .nombre-usuario{
            font-weight: bold;
            min-width: 9.5em;
        }

        @media screen and (max-width: 500px) {   
            min-width: auto;            
        }

        .sk-nombre-usuario{
            font-weight: bold;
            height: 1.5em;
            width: 100%;
        }

        @media (max-width: 500px) {   
            .nombre-usuario{
                font-size: 16px;
            }
        }
    }

    @media (max-width: 500px) {   
        .gestionar-nombre-img-terapeuta, .gestionar-nombre-img{
            width: 65%;
        }
    }    

    .gestionar-item-btns{
        width: 70%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-areas: "asignar . . . perfil eliminar";
        column-gap: 5px;
        font-size: 16px;

        .btn-gestionar{
            width: 90px;
        }

        .sk-btn-gestionar{
            width: 5.625em;
            height: 1.9027em;
        }

        #btn-asignar{
            grid-area: asignar;
        }

        #btn-perfil{
            grid-area: perfil;
        }

        #btn-eliminar{
            grid-area: eliminar;
            border: 1px solid $gris-6;
            background-color: $gris-6;
            padding: 5px 12.5px;
            font-weight: bold;
            user-select: none;
        }
        
        #sk-btn-eliminar{
            grid-area: eliminar;
        }

        @media (max-width: 500px) {   
            .btn-gestionar{
                min-width: 75px;
                padding: 3px 11px;
                font-size: 12px;
            }
        }

        @media (max-width: 350px) {   
            .btn-gestionar{
                width: 60px;
                padding: 3px 11px;
                font-size: 10px;
            }
        }
    }

    @media (max-width: 500px) {   
        .gestionar-item-btns{
            grid-template-columns: repeat(1, 1fr);
            grid-template-areas: "asignar" 
                                 "perfil"
                                 "eliminar";
            row-gap: 10px;
            width: 30%;
        }
    }
}
.profile-gestionar-img{
    object-fit: cover;
    border-radius: 50%;
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    margin-right: 10px;
}

.sk-profile-gestionar-img{
    width: 2.5em;
    min-width: 2.5em;
    height: 2.5em;
    margin-right: 0.625em;
    font-size: 16px;
}

@media (max-width: 700px) {   
    .gestionar-panel{
      width: 90%;
    }
}

