.fondo-dialogo-crear-terapia {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  overflow-y: auto;

  .fondo-dialogo-rc {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 300;
    position: fixed;
    background-color: $gris-7;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    flex-direction: column;
  }

  .dialogo-crear-terapia {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    max-width: 25%;
    width: 100%;
    background-color: white;
    padding: 1em 3em;
    z-index: 310;
    @include border-radius(30px);
    @include box-shadow(6px, 6px, 6px, 0, rgba(0, 0, 0, 0.16));

    .titulo-dialogo-crear-terapia {
      align-self: center;
      margin-top: 1em;
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;

      hr {
        height: 0.3125em;
        background-color: $terapeuta;
        border: none;
        border-radius: 5px;
        width: 8em;
        margin: 0.3em auto;
      }
    }

    .form {
      margin: 0;
      width: 100%;

      .input {
        width: 100%;
        outline: none;
        font-size: 0.8em;
        font-weight: 600;
        border: 0;
        background-color: white;
        border-bottom: 2px solid rgba(223, 223, 223, 1);
        margin-bottom: 1em;
      }

      .input:focus {
        border-bottom: 2px solid $terapeuta;
      }

      .fila-btnes {
        margin-top: 2em;
        display: flex;
        justify-content: center;

        .btn-izq {
          margin-right: 0.5em;
        }

        .btn-der {
          margin-left: 0.5em;
        }
      }
    }
  }
}

@media screen and ( max-width: 800px ){
  .fondo-dialogo-crear-terapia {
    .dialogo-crear-terapia {
      max-width: 70%;
    }
  }
}