.contenedor-perfil-terapeuta
    margin-top: 1.25em
    display: flex
    flex-direction: column
    width: 75%
    min-width: 28.125em
    font-size: 16px

    .encabezado-perfil-paciente
        position: relative

        .path-perfil-paciente
            display: flex
            align-items: center
            width: fit-content
            cursor: pointer
            user-select: none

            b
                font-size: 1.25em

            .flecha-decoracion
                right: 0.3125em
                background-color: $paciente
                color: white
                font-weight: bold
                height: 1.5625em
                width: 1.875em
                border-radius: 40%
                display: grid
                place-items: center
                padding: 0.0625em
                vertical-align: middle
                margin-right: 0.625em


    .perfil-terapeuta
        align-self: center
        display: grid
        align-items: center
        grid-template-columns: 1fr 5fr
        grid-template-areas: ". titulo" "foto perfil"
        justify-items: center
        height: fit-content
        max-width: 50em

        .seccion-foto-cambiar
            grid-area: foto
            align-self: center
            display: flex
            flex-direction: column
            align-items: center
            min-width: 11.25em

            .nombre
                margin-bottom: 1em
                font-weight: bold
                text-align: center

            .img-perfil
                border-radius: 50%
                width: 8.75em
                height: 8.75em
                margin-bottom: 1em       
                object-fit: cover
                
            
        .sk-seccion-foto-cambiar 
            grid-area: foto
            margin: 1.25em 0
            height: 11.5486em
            width: 11.25em
            min-width: 11.25em
        
        .titulo-perfil-terapeuta
            grid-area: titulo
            font-size: 2em
            font-weight: bold
            width: fit-content
            display: flex
            flex-direction: column
            align-items: center
            text-align: center
            
            hr 
                width: 85%
                margin: 0
                border: none
                height: 0.25em
                border-radius: 5px
    
        .contenedor-perfil
            width: 75%
            grid-area: perfil
            margin: 1.25em 0
        
            .fila-dato
                font-size: 1em
                
                .campo
                    font-weight: bold
                

                .campo, .valor
                    font-size: 1.125em
                    word-wrap: break-word
                

                .sk-valor 
                    height: 1.6669em
                    margin: 1.125em 0em
                    width: 60%
                
                .sk-descripcion 
                    width: 60%
                    height: 4.6875em
                    margin: 1.125em 0em

            > button 
                margin-top: 2em
                width: fit-content
            

            .btn-azul
                margin-top: 1.25em
                width: fit-content
            

            .sk-btn-azul 
                margin-top: 1.25em
                width: 6.3944em
                height: 1.8613em

            > .error 
                margin-top: 1em
                text-align: center
                font-size: 1em        

        .background-paciente 
            background-color: $paciente

        .btn-paciente 
            background-color: $paciente
            color: white
            border: none

    @media (max-width: 700px)  
        .perfil-terapeuta
            width: 100%
            grid-template-columns: 1fr
            grid-template-areas: "titulo" "perfil" "foto"

@media screen and ( max-width: 500px)
    .contenedor-perfil-terapeuta
        font-size: 3vw

@media screen and ( min-width: 1600px)
    .contenedor-perfil-terapeuta
        font-size: 1vw

    


    
