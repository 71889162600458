.info-usuario-admin{
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    .contenedor-perfil-usuario-admin {
        display: flex;
        flex-direction: column;
        width: 75%;
        font-size: 1em;
    
        .perfil-usuario{
            width: 50%;
            min-width: 450px;
            align-self: center;
            padding-bottom: 2em;
            font-size: 16px;

            .contenedor-botones-perfil {
                margin-top: 1.5em;
                display: flex;
                align-items: center;
                
                >.btn-azul{
                    margin-right: 2em;
                }

                >.sk-btn-azul{
                    margin-right: 1em;
                }
            }


            .img-nombre-usuario-admin{
                display: flex;
                align-items: center;
                margin-top: 10px;

                .img-usuario-admin{
                    width: 77px;
                    height: 77px;
                    object-fit: cover;
                    border-radius: 50%;
                    margin-right: 10px;
                }
                
                .sk-img-usuario-admin{
                    width: 4.8125em;
                    margin-right: 0.625em;
                    height: 4.8125em;
                }
                
                @media (max-width: 350px) { 
                    .img-usuario-admin{
                        font-size: 30px;
                    }
                }

                .titulo-perfil-usuario-admin{
                    font-size: 2em;
                    font-weight: bold;
                    width: 75%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                
                    hr {
                        width: 55%;
                        margin: 0;
                        border: none;
                        height: 4px;
                        border-radius: 5px;
                        background-color: $administrador;
                    }
                
                    @media (max-width: 500px) { 
                        hr{
                            width: 150px;
                        }
                    }
                }
                
                .sk-titulo-perfil-usuario-admin{
                    width: 75%;
                    height: 3.3056em;
                }
                
                @media (max-width: 350px) { 
                    .titulo-perfil-usuario-admin{
                        font-size: 22px;
                    }
                }
            }
            
            .btn-borde-negro {
                padding: 10px 15px;
                font-size: 12px;
            }

            .sk-btn-borde-negro {
                width: 8.6198em;
                height: 2.6113em;
            }

            .sk-descripcion {
                width: 60%;
                height: 4.6875em;
                margin: 1.125em 0em;
            }
    
            @media (max-width: 300px) { 
                .btn-borde-negro {
                    font-size: 10px;
                    padding: 7.5px 12px;
                }
            }
        }
    
        @media (max-width: 500px) { 
            .perfil-usuario{
                width: 90%;
                min-width: 300px;
            }
        }

        @media (max-width: 350px) { 
            .perfil-usuario{
                min-width: 250px;
            }
        }
    
        .fila-dato{
            align-items: center;
            font-size: 1em;
            .campo{
                font-size: 18px;
                font-weight: bold;
            }
    
            .valor{
                font-size: 18px;
                word-break: break-word;
            }

            .sk-valor {
                height: 1.6669em;
                margin: 1.125em 0em;
                width: 60%;
            }
        
            @media (max-width: 400px) { 
                .valor, .campo{
                    font-size: 15px;
                }
            }
    
            @media (max-width: 300px) { 
                .valor, .campo{
                    font-size: 12px;
                }
            }

            .dropdown-perfil-admin{
                width: 60%;
                margin: 10px 0;
            
                .custom-select-perfil-admin {
                    border-radius: 0.3125em;
                    user-select: none;
                    background-color: white;
                    position: relative;
                    height: 30px;
                    outline: none;
                    display: flex;
                    align-items: center;
                    z-index: 5;
                    width: 75%;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    border: solid black 1px;
                    padding-left: 5px;

                    .nombre-terapeuta {
                        width: 80%;
                        margin: 0;
                        font-size: 0.8em;
                        overflow-x: scroll;
                        white-space: nowrap;

                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                
                    .flecha-decoracion-select{
                        position: absolute;
                        right: 5px;
                        background-color: $administrador;
                        color: white;
                        font-weight: bold;
                        width: 20px;
                        height: 20px;
                        border-radius: 40%;
                        display: grid;
                        place-items: center;
                        padding: 1px;
                        font-size: 16px;
                
                        span{
                            margin-top: -3px
                        }
                    }
                }    
                
                .lista-usuarios-perfil-admin{
                    width: calc(75% + 5px);
                    margin-top: -13px;
                    padding-top: 13px;
                    padding-bottom: 0.05px;
                    border: solid black 1px;
            
                    .ul-usuarios-perfil-admin {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        
                        li {
                            font-size: 0.8em;
                            user-select: none;
                            padding: 0.2em 0 0.2em 0.625em;
                            cursor: pointer;

                            overflow-x: scroll;
                            white-space: nowrap;
    
                            &::-webkit-scrollbar {
                                display: none;
                            }
                        }
                    
                        li:hover {
                            background-color: $administrador;
                            color: white;
                        }
                      }
                
                }
            }
        }
    }

    @media (max-width: 1200px) { 
        .contenedor-perfil-usuario-admin{
            width: 90%;
        }
    }
}

@media screen and (max-width: 500px) {
    .info-usuario-admin {
        font-size: 3vw;
    }
}