.contenedor-perfil-paciente
    margin-top: 1.25em
    display: flex
    flex-direction: column
    width: 75%
    min-width: 28.125em
    font-size: 16px

    .encabezado-perfil-paciente
        position: relative

        .path-perfil-paciente
            display: flex
            align-items: center
            width: fit-content
            cursor: pointer
            user-select: none

            b
                font-size: 1.25em

            .flecha-decoracion
                right: 0.3125em
                background-color: $terapeuta
                color: white
                font-weight: bold
                height: 1.5625em
                width: 1.875em
                border-radius: 40%
                display: grid
                place-items: center
                padding: 0.0625em
                vertical-align: middle
                margin-right: 0.625em


        .btn-asignar-terapia
            top: 0
            right: 0
            position: absolute
            border: none
            background-color: $terapeuta
            color: white
            width: fit-content
            padding-left: 1em
            padding-right: 1em

    .perfil-paciente
        width: 50%
        min-width: 28.125em
        align-self: center
        padding-bottom: 2em

        .img-nombre-paciente
            display: flex
            align-items: center
            margin-top: 0.625em

            .img-paciente
                width: 4.8125em
                height: 4.8125em
                object-fit: cover
                border-radius: 50%
                margin-right: 0.625em

            .sk-img-paciente
                width: 4.8125em
                margin-right: 0.625em
                height: 4.8125em
            
            .titulo-perfil-paciente
                font-size: 2em
                font-weight: bold
                width: 75%
                display: flex
                flex-direction: column
                align-items: center
                text-align: center
            
                hr
                    width: 55%
                    margin: 0
                    border: none
                    height: 0.125em
                    border-radius: 5px
                    background-color: $terapeuta
            
            .sk-titulo-perfil-paciente
                width: 75%
                height: 3.3056em


        .fila-dato
            align-items: center

            >.titulo-terapias-asignadas
                align-self: start
                padding: 1em 0em
                width: 40%
                font-size: 1.125em
                margin: 0
                font-weight: bold

            .campo
                font-size: 1.125em
                font-weight: bold
            
            .valor
                font-size: 1.125em

            .sk-valor 
                height: 1.6669em
                margin: 1.125em 0em
                width: 60%

            .sk-descripcion
                width: 60%
                height: 4.6875em
                margin: 1.125em 0em

            .lista-terapias
                display: flex
                flex-direction: column
                width: 60%
                min-width: 10.625em

                .terapia
                    display: flex
                    align-items: center
                    justify-content: space-between
                    width: 100%
                    padding: 1em 0
                    font-size: 1.125em

                    >.nombre
                        width: 10em
                
                    .btn-revisar
                        font-size: 0.6em
                        max-width: 10em

                    .circulo-revisar
                        background-color: $terapeuta
                        height: 1.2em
                        width: 1.2em
                        border-radius: 50%
                        position: absolute
                        transform: translateX(7.5em) translateY(-2.5em)
                        border: 1px solid white        
        .btn-chat
            background-color: $gris-3
            border: none
            color: white
            width: fit-content
            padding: 0 1.5em
            margin-top: 1em
            
@media screen and (max-width: 500px)
    .contenedor-perfil-paciente
        font-size: 3vw

        .path-perfil-paciente
            margin-bottom: 2em

@media screen and (min-width: 1600px)
    .contenedor-perfil-paciente
        font-size: 1vw