.dialogo-asignar-terapia
    background-color: white
    width: 28.125em
    border-radius: 0.9375em
    display: flex
    flex-direction: column
    align-items: center
    box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16)
    font-size: 16px
    max-width: 90%

    .contenido-dialogo
        width: 80%
        display: flex
        flex-direction: column
        align-items: stretch

        .titulo-dialogo
            margin-top: 1em
            font-size: 1.1em
            font-weight: bold
            text-align: center
            hr
                height: 0.3125em
                background-color: $terapeuta
                border: none
                border-radius: 5px
                width: 8em
                margin: 0 auto 1em auto

        .titulo-dropdown
            font-weight: bold
            font-size: 0.9em
            margin-bottom: 0.5em

        .botones-dialogo
            display: flex
            justify-content: center
            margin: 1.25em 0
  
            > button 
                font-size: 0.7688em
                padding: 0.24em 0

            > .btn-asignar
                background-color: $terapeuta
                border: none
                color: white
                margin-left: 0.95em

        .error
            text-align: center
            margin-top: 1em
            width: 70%
            align-self: center
            font-size: 0.9em

            