.info-paciente {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;

  .titulo-bienvenida {
    font-size: 2em;
  }

  .sk-titulo-bienvenida {
    width: 40%;
    height: 3em;
    margin: 1.5em;
  }

  .contenedor-resumen {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .contenedor-blanco {
      background: #ffffff 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 45%;
      padding: 1rem;
      height: fit-content;

      .btn-paciente-sin-datos {
        width: fit-content;
        background: $gris-btn;
        padding: 0 1.5em;
        color: white;
        border: 0;
        font-weight: 500;
      }

      .subtitulo {
        margin: 1em 0 1.5em;
      }

      .sk-subtitulo {
        height: 1.5em;
        width: 35%;
        margin-bottom: 1em;
      }

      .espacio-texto {
        margin: 1rem 2rem;

        .fila-info {
          display: flex;
          align-items: center;
          margin-bottom: 1em;

          .conte-foto {
            width: 20%;

            .foto-persona {
              @include border-radius(50%);
              object-fit: cover;
              width: 2.7em;
              height: 2.7em;
            }
          }

          .sk-foto-persona {
            height: 2.5em;
            width: 2.5em;
          }

          .nombre-terapeuta {
            width: 50%;
            padding-right: 1.2em;
            margin: 0;
            font-size: 1em;
            font-weight: 600;

            @media screen and (max-width: 800px) {
              padding-right: 0;
            }
          }

          .sk-nombre-terapeuta {
            height: 2em;
            width: 40%;
            margin-left: 1em;
          }

          .btn-verde {
            width: 30%;
            margin: 0 0.5em 0 auto;
          }

          .sk-btn-verde {
            height: 2em;
            width: 27%;
            margin-left: auto;
          }

          .columna-terapias {
            display: flex;
            flex-direction: column;
            width: 70%;

            .negrilla {
              font-weight: 600;
            }

            .nombre-terapia {
              margin: 0;
              font-weight: 600;
            }

            .sk-nombre-terapia {
              height: 1em;
              width: 50%;
            }

            .tareas-completadas {
              margin: 0;
            }

            .sk-tareas-terapia {
              height: 1em;
              width: 95%;
              margin-top: 0.1em;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .info-paciente {
    width: 90%;

    .titulo-bienvenida {
      width: 90%;
      text-align: center;
    }

    .contenedor-resumen {
      flex-direction: column;

      .contenedor-blanco {
        width: 90%;
        margin-bottom: 2em;

        .espacio-texto {
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .fila-info {
            flex-direction: column;
            align-items: center;
            width: 100%;

            .foto-persona {
              margin-bottom: 0.6em;
            }

            .sk-nombre-terapeuta {
              padding-left: 0;
              margin: 0.5em 0;
              height: 1.5em;
            }

            .nombre-terapeuta {
              padding-left: 0;
              margin-bottom: 0.3em;
            }

            .btn-verde {
              margin: 0.3em 0;
              font-size: 0.8em;
            }
            .sk-btn-verde {
              margin: 0.3em 0;
            }

            .columna-terapias {
              align-items: center;
            }
          }
        }
      }
    }
  }
}
