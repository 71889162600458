header
    display: flex
    justify-content: space-around
    align-items: center
    background-color: white
    width: 94%
    padding: 1.3vw 3%
    position: fixed
    left: 0
    right: 0
    top: 0
    z-index: 500
    transition: 1s all ease

.sk-imagen-logo
    font-weight: bold
    height: 2.3em
    width: 13%

.imagen-header
    color: $gris-1
    width: 13%
    text-decoration: none
    display: flex
    align-items: center

    svg
        width: 100%

    img
        width: 100%

.opciones-header
    width: 45%
    max-width: 30em
    display: flex
    justify-content: space-around
    align-items: center

.opcion-header
    width: 35%
    font-size: 1.1em
    color: $gris-1
    text-decoration: none
    font-weight: 500
    padding: 0.5vw 0
    text-align: center

    &:hover
        hr
            height: 0.4em

    hr
        width: 30%
        height: 0
        border: none
        transition: 0.5s all ease
        margin: 0.2em auto 0
        +border-radius(3em)

    .hr-verde
        background-color: $paciente

    .hr-azul
        background-color: $administrador

    .hr-amarillo
        background-color: $amarillo

    .seleccionado
        height: 0.4em
        

.opciones-login-header
    display: flex
    justify-content: space-around
    width: 17em

    .sk-boton-header
        width: 9em
        height: 2.5em

.header-sesion
    display: flex
    justify-content: space-between
    align-items: center
    background-color: white
    width: 100%
    box-sizing: border-box
    padding: 20px 9%
    box-shadow: 0px 3px 6px #00000029

    .opciones-header-perfil
        display: flex
        align-items: center

        .sk-boton-header
            width: 9em
            height: 2.5em


.btn-perfil
    background: none
    border: none
    display: flex
    justify-content: center
    align-items: center
    padding: 0
    outline: none
    cursor: pointer
    position: relative
    margin-right: 10px
    text-decoration: none
    color: $gris-2

    .sk-foto
        width: 3vw
        height: 3vw

    .sk-nombre-header
        height: 1.4em
        width: 9em
        margin-left: 0.5em

    img
        width: 3vw
        height: 3vw
        border-radius: 100%
        object-fit: cover   

    svg
        width: 3vw
        height: 3vw 

    .triangulo-opciones
        position: absolute
        top: 80%
        right: 10%
        border-left: 0.7vw solid transparent
        border-top: 0.7vw solid transparent
        border-right: 0.7vw solid $gris-4
        border-bottom: 0.7vw solid $gris-4

    .opciones-sesion
        position: absolute
        top: 80%
        right: 5%
        background-color: $gris-4    
        width: 10vw
        display: flex
        flex-direction: column
        align-items: center
        margin-top: 1.2vw
        z-index: 500
        padding: 0.5vw 0
        border-radius: 10px

        .separador-opciones
            background: white
            height: 0.2vw
            width: 85%

        .opcion-sesion
            width: 100%
            background: none
            border: none
            font-size: 1.1vw
            cursor: pointer
            outline: none
            text-decoration: none
            text-align: start
            padding: 0.5vw 10%
            color: black
            font-weight: normal


.flechas
    width: 2vw
    height: 2vw
    position: relative

    div
        width: 0.7vw
        height: 0.7vw
        position: absolute
        top: 0
        left: 0
        border-bottom: solid 0.1vw black
        border-right: solid 0.1vw black

        &:nth-child(1)
            transform: translateY(0.4vw) rotateZ(45deg)
        
        &:nth-child(2)
            transform: translateY(0.7vw) rotateZ(45deg)

    @media screen and ( max-width: 800px )
        display: none
            

.nombre-header
    margin: 0 15px
    white-space: nowrap
    font-size: 16px
    font-weight: bold

.opciones-sesion-container
    display: flex
    justify-content: flex-end
    align-items: flex-start
    position: fixed
    width: 100%
    height: 100vh
    top: 0
    left: 0
    background-color: rgba(0, 0, 0, .4)

.cerrar-opciones
    width: 100vw
    height: 100vh
    position: absolute
    z-index: 400
    top: 0
    left: 0
    /* background-color: rgba(0, 0, 0, .4) */

.menu-responsive
    background: none
    border: none
    outline: none
    position: fixed
    top: 0.2em
    width: 5.5em
    height: 5.5em
    display: none
    transition: 1s all ease

    img
        width: 100%
        height: 100%
        object-fit: contain

    svg
        width: 2em
        height: 2em
        border-radius: 100%
        overflow: hidden

        path
            color: white

    @media screen and ( max-width: 800px )
        display: block

    @media screen and ( max-width: 400px )
        width: 2em
        height: 2em

.loading-indicator
    background-color: rgba(0, 0, 0, 0.2)
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    position: fixed
    display: grid
    place-items: center
    z-index: 1500

@media screen and ( max-width: 800px )

    .header-sesion

        .opciones-header-perfil
            width: 100%
            justify-content: space-between

            .sk-boton-header
                width: 6em
                height: 1.8em

    header
        flex-direction: column

    .menu-grande-responsive
        width: 100vw
        height: 80vh
        position: fixed
        top: 0
        left: 0
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 10vh 0
        transition: 1s all ease

    .imagen-header
        display: none

    .sk-imagen-logo
        display: none
        
    .opciones-header
        flex-direction: column
        width: 90%
        margin: 2em auto 0


    .opcion-header
        font-size: 1.6em

    .opciones-login-header
        margin: 1em 0
        align-self: flex-end

    .btn-perfil

        .sk-foto
            width: 10vw
            height: 10vw

        img
            width: 40px
            height: 40px
            object-fit: cover  

        .nombre-header
            width: 40vw
            overflow: hidden
            white-space: nowrap
            text-overflow: ellipsis
            
        .sk-nombre-header
            width: 40vw

@media screen and ( max-width: 500px )
    .header-sesion
        padding: 20px 3%


