.contenedor-revision-actividades {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;

  @media screen and (max-width: 800px) {
    width: 90%;
  }

  .fila-atras {
    display: flex;
    align-items: center;
    margin-top: 1em;
    cursor: pointer;
    width: fit-content;
    align-self: flex-start;

    .texto-atras {
      font-size: 1em;
      margin: 0 0 0 0.5em;
    }

    .sk-texto-atras {
      width: 4em;
      height: 1em;
      margin: 0 0 0 0.5em;
    }

    .btn-atras {
      font-size: 16px;
      width: 2.25em;
      margin: 0 0.37em 0 0;
      padding: 0.3em;
      height: fit-content;
    }

    .sk-btn-atras {
      width: 2.25em;
      height: 2em;
      margin: 0 0.37em 0 0;
    }
  }

  .titulo-terapia {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 1em 0 0.5em;

    .nombre-terapia {
      font-size: 1.5em;
      font-weight: 600;
      text-align: center;
      margin: 0;
    }

    .sk-titulo-terapias {
      height: 2em;
      width: 40%;
      margin: 0;
    }
  }

  .contenedor-actividades {
    margin-top: 2em;
    width: 100%;

    .sin-actividades {
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 100%;
      padding: 0.5em 0;

      .texto {
        text-align: center;
        font-size: 1.2em;
        font-weight: 600;
      }
    }

    .sk-actividad {
      width: 100%;
      height: 9em;
      margin-bottom: 1em;
    }

    .con-actividad {
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 0 1em 0;
      padding: 0.2em 0;

      .entregado {
        position: absolute;
        right: 0;
        top: 0;
        margin-right: 2em;
        color: $terapeuta;
        font-style: italic;
      }

      .entregado-admin {
        color: $administrador;
      }

      .content {
        margin: 2em 2em 1em;
        display: flex;
        flex-direction: column;
        width: 100%;

        .descripcion {
          font-weight: normal;
          text-align: justify;
        }

        .recursos {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-bottom: 1em;

          .link {
            color: #3e4348;
            margin-bottom: 1em;
            width: 100%;
          }

          .info-video{
            display: flex;

            .archivos {
              width: 70%;
              display: flex;
              flex-direction: column;

              .nombre-archivo {
                margin: 0;
              }
            }

            .descargar-archivo {
              width: 30%;
              color: $terapeuta;
              text-decoration: none;
              font-weight: 600;
              align-self: center;
            }
            .descargar-admin{
              color: $administrador;
            }

            .video {
              width: 30%;
            }

            .datos-video {
              width: 70%;
              margin-left: 2em;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .titulo-video {
                margin: 0;
              }
            }

            @media (max-width: 800px) {
              flex-direction: column;
              width: 95%;
            }
          }
        }

        .contenedor-respuesta {
          border: 1px solid #000000;

          .respuesta {
            padding: 0.5em 1em;
          }
        }

        .fila-archivo {
          display: flex;
          align-items: center;
          margin-bottom: 0.5em;
          margin-right: 1em;

          .nombre-archivo {
            margin: 0 1em;
          }

          .descargar {
            color: $terapeuta;
            text-decoration: none;
            font-weight: 600;
          }
          .descargar-admin {
            color: $administrador;
          }
        }
      }
    }
  }
  .btn-revisada {
    width: fit-content;
    padding: 0 1em;
    margin-top: 1em;
    margin-bottom: 3em;
    align-self: flex-end;
  }
}

.contenido-actividad{
  display: flex;
  justify-content: space-between;
  width: 100%;

  .boton-eliminar{
    width: 2em;
    height: 2em;
    background: none;
    border: none;
    margin: 0.5em 1em;
    cursor: pointer;
    outline: none;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

  }

  .texto{
    width: 100%;
  }

}