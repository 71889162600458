.contenedor-terapias {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding-bottom: 2em;

  .sin-terapias{
    margin-top: 1em;
    background: #FFFFFF 0 0 no-repeat padding-box;
    @include box-shadow(3px, 3px, 6px, 0, #00000029);
    @include border-radius(0.9em);
    width: 100%;
    padding: 0.5em 0;

    .texto {
      text-align: center;
      font-size: 1.2em;
      font-weight: 600;
    }
  }

  .lista-terapias {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2em;
    grid-column-gap: 2em;
    justify-items: center;
    width: 95%;

    .contenedor-blanco {
      cursor: pointer;
      background: #FFFFFF 0 0 no-repeat padding-box;
      @include box-shadow(3px, 3px, 6px, 0, #00000029);
      @include border-radius(0.9em);
      width: 90%;
      padding: 1.2em;
      height: fit-content;

      .espacio-texto {
        margin: 1rem 1.5em;

        .fila-titulo-terapia {
          // display: flex;
          align-items: center;
          margin-bottom: 0.7em;

          .titulo-terapia {
            margin: 0;
            font-weight: 700;
            font-size: 1.2em;
          }

          .tarea {
            margin-left: 1em;
            display: flex;
            flex-direction: column;
            align-items: center;
            float: right;

            .estado-terapia {
              margin: 0;
              font-size: 1.1em;
              font-style: italic;
              text-align: center;
            }

            .subrayado {
              background-color: #2EC573;
              border: 0;
              height: 0.25em;
              margin: 0;
              @include border-radius(10rem);
              width: 90%;
            }
          }
        }

        .descripcion-terapia {
          margin: 0;
          font-size: 0.9em;
          font-weight: 500;
          text-align: justify;
        }
      }
    }

    .sk-contenedor-blanco {
      width: 93%;
      height: 8em;
    }
  }
}

@media screen and ( max-width: 800px ){
  .contenedor-terapias {
    width: 95%;

    .lista-terapias {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}