/*
Estilos de la clase Paciente
*/
.pantalla-pacientes {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $gris-5;
  min-height: 100vh;
  
  @import '../general/loadingIndicator/loading-indicator';
  
  .linea-menu{
    margin: 0;
    height: 0.5px;
    width: 75%;
    background-color: #949494;
    border: none;
  }

  .contenido-paciente {
    width: 100%;
    display: flex;
    justify-content: center;

    @import "components/resumen/resumen";
    @import "components/terapias/terapias";
    @import "components/detalleTerapia/detalle-terapia";
    @import './../general/citas/citas';
    @import './../general/citas/itemCita/item-cita';
    @import './../general/Perfil/perfil';
    @import './components/perfilTerapeuta/perfil-terapeuta';
  }
}

.fila-flex {
  display: flex;
  width: 100%;
}