$azul: rgb(217, 254, 255)
$azul-opacidad: rgb(217, 254, 255, 0.7)
$gris-1: rgb(38,38,38)
$gris-2: rgb(58,58,58)
$gris-3: rgb(109,109,109)
$gris-4: rgb(229,229,229)
$gris-5: rgb(249, 249, 249)
$gris-6: rgb(216,216,216)
$gris-7: rgba(220, 220, 220, 0.58)
$gris-8: #5e5e5e

$gris-btn: #949494

$fondo-gris: rgb(245, 245, 245)

$administrador: rgb(65,142,238)
$rojo: #ee4141
$administrador-sombra: rgb(65,142,238, 0.57)
$paciente: rgb(46, 197, 115)
$paciente-sombra: rgb(46, 197, 115, 0.57)
$terapeuta: rgb(255, 125, 81)
$terapeura-sombra: rgba(255, 125, 81, 0.57)

$amarillo: rgb(255, 189, 66)
$amarillo-sombra: rgb(255, 189, 66, 0.57)