.fondo-dialogo-generico
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1300
  position: fixed
  background-color: rgba(0, 0, 0, 0.4)
  -webkit-tap-highlight-color: transparent
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: 16px

  .fondo-dialogo-rc
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1301
    position: absolute
    -webkit-tap-highlight-color: transparent
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center


  .dialogo
    display: flex
    flex-direction: column
    align-items: center
    width: 27.5%
    min-width: 21.875em
    padding: 2em 1.5em
    background-color: white
    z-index: 1302
    @include border-radius(30px)

    .titulo-dialogo
      text-align: center
      font-size: 1.5em
      font-weight: bolder
      color: black

    .descripcion-dialogo
      text-align: center
      font-size: 1em
      color: black
      width: 100%
      margin: auto

    input
      width: 50%
      height: 3.5vw
      padding: 0 2vw
      border-radius: 1vw
      border: solid 0.1vw black
      margin: 2vw 0 0
      font-size: 1.3vw
      color: $gris-1
      font-weight: 600
      outline: none
      text-align: center

@media screen and (max-width: 500px)
  .fondo-dialogo-generico
    font-size: 3vw

@media screen and (min-width: 1600px)
  .fondo-dialogo-generico
    font-size: 1vw


.textoCargando
  color: white
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  background: rgba(0, 0, 0, .6)
  display: flex
  justify-content: center
  align-items: center
  font-size: 2.5vw
  font-weight: bold
  text-shadow: 1px 1px black
  position: fixed
  z-index: 3


.inputRC
  width: 30vw
  height: 3vw
  margin: 2vw 0
  border-radius: 0.5vw
  padding: 0.2vw 0.5vw
  font-size: 1.4vw
  border: solid 1px rgb(62, 67, 72)


.boton-dialogo
  border-radius: 1em
  padding: 1em
  color: white
  font-weight: 500
  margin-top: 1em
  outline: none

#btn-primario
  margin-right: 1em


.lds-ring
  display: flex
  position: fixed
  width: 100%
  height: 100vh
  justify-content: center
  align-items: center
  background: rgba(0, 0, 0, 0.4)
  z-index: 1000
  top: 0
  left: 0

.lds-ring div
  box-sizing: border-box
  display: block
  position: absolute
  width: 5vw
  height: 5vw
  margin: 6px
  border: 0.7vw solid $azul
  border-radius: 50%
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
  border-color: $azul transparent transparent transparent

.lds-ring div:nth-child(1)
  animation-delay: -0.45s

.lds-ring div:nth-child(2)
  animation-delay: -0.3s

.lds-ring div:nth-child(3)
  animation-delay: -0.15s

@keyframes lds-ring
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)


.lds-ring-local
  display: flex
  width: 4vw
  height: 4vw
  justify-content: center
  align-items: center
  background: none

.lds-ring-local div
  box-sizing: border-box
  display: block
  position: absolute
  width: 4vw
  height: 4vw
  margin: 0
  border: 0.6vw solid $azul
  border-radius: 50%
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
  border-color: $azul transparent transparent transparent

.lds-ring-local div:nth-child(1)
  animation-delay: -0.3s

.lds-ringlocal div:nth-child(2)
  animation-delay: -0.3s

.lds-ring-local div:nth-child(3)
  animation-delay: -0.15s


@media only screen and (max-width: 768px)

  .dialogo
    width: 70%

  .titulo-dialogo
    font-size: 5vw


  .descripcion-dialogo
    font-size: 3.5vw

  .textoCargando
    font-size: 10vw
    
